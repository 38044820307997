import styled from "styled-components";

export const StyledHeader = styled.header`
  &#qodef-page-header {
    position: sticky;
    top: 0;
    background-color: ${({ baseColor }) => baseColor} !important;
  }
`;

export const StyledMobileHeader = styled.header`
  &#qodef-page-mobile-header {
    position: sticky;
    top: 0;
    background-color: ${({ baseColor }) => baseColor} !important;
    .qodef-mobile-header-navigation {
      background-color: ${({ baseColor }) => baseColor} !important;
    }
  }
`;
