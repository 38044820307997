import React from "react";
import { LazyLoadImage, LazyLoadComponent, trackWindowScroll } from "react-lazy-load-image-component";

import 'react-lazy-load-image-component/src/effects/opacity.css';

const LazyImage = ({forwardRef, ...rest}) => (
  <LazyLoadComponent>

    <LazyLoadImage
      {...rest}
      effect="opacity"
      className="attachment-full size-full"
      wrapperClassName="attachment-full size-full"
      wrapperProps={{
        style: {
          display: "block",
        },
      }}
    />
  </LazyLoadComponent>
);

export default trackWindowScroll(LazyImage);
