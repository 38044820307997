import React from "react";

export const ListCircleIcon = () => {
  return (
    <span className="qodef-m-icon-wrapper">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
      >
        <circle
          cx="7"
          cy="7"
          r="6.25"
          stroke="black"
          strokeWidth="1.5"
        ></circle>
      </svg>
    </span>
  );
};
