export const gallery = [
  {
    href: "/img/in-de-maak/in-de-maak.mp4",
    title: "In de Maak",
    width: "800",
    height: "1067",
    type: "video",
    src: "/img/in-de-maak/in-de-maak.mp4",
  },
  {
    href: "/img/in-de-maak/Zoom-scaled.jpg",
    title: "Zoom",
    width: "1811",
    height: "2560",
    src: "/img/in-de-maak/Zoom-scaled.jpg",
  },
  {
    href: "/img/in-de-maak/WhatsApp-Image-2022-03-29-at-4.30.50-PM.jpeg",
    title: "WhatsApp Image 2022-03-29 at 4.30.50 PM",
    width: "1600",
    height: "1040",
    src: "/img/in-de-maak/WhatsApp-Image-2022-03-29-at-4.30.50-PM.jpeg",
  },
  {
    href: "/img/in-de-maak/render-actie-scaled.jpg",
    title: "render actie",
    width: "1811",
    height: "2560",
    src: "/img/in-de-maak/render-actie-scaled.jpg",
  },
  {
    href: "/img/in-de-maak/render-actie_2-scaled.jpg",
    title: "render actie_2",
    width: "1811",
    height: "2560",
    src: "/img/in-de-maak/render-actie_2-scaled.jpg",
  },
  {
    href: "/img/in-de-maak/render-actie_1-scaled.jpg",
    title: "render actie_1",
    width: "1811",
    height: "2560",
    src: "/img/in-de-maak/render-actie_1-scaled.jpg",
  },
  {
    href: "/img/in-de-maak/De-Bijenkorf-In-De-Maak-x-N-J-54-scaled.jpg",
    title: "De Bijenkorf- In De Maak x N-J-54",
    width: "1708",
    height: "2560",
    src: "/img/in-de-maak/De-Bijenkorf-In-De-Maak-x-N-J-54-scaled.jpg",
  },
];
