import React from "react";
import LazyImage from "../../components/data-display/lazy-image/LazyImage";

const AboutHero = () => {
  return (
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-5455663 elementor-section-full_width elementor-section-height-default elementor-section-height-default qodef-backgound-letter-holder-no qodef-elementor-content-no"
      data-id="5455663"
      data-element_type="section"
      style={{ overflow: "visible" }}
    >
      <div className="elementor-container elementor-column-gap-default">
        <div
          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-a118be5"
          data-id="a118be5"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-989e32d elementor-widget elementor-widget-laurits_core_single_image"
              data-id="989e32d"
              data-element_type="widget"
              data-widget_type="laurits_core_single_image.default"
            >
              <div className="elementor-widget-container">
                <div
                  className="qodef-shortcode qodef-m  qodef-single-image qodef-single-image-148 qodef-layout--default  qodef-appear-animation--no qodef-zoom-scroll-animation--no"
                  data-appearing-delay="0"
                >
                  <div className="qodef-m-image">
                    <LazyImage
                      width="3153"
                      height="1417"
                      // style={{ maxHeight: 640, objectFit: "cover" }}
                      src="/img/studio-pms.jpg"
                      className="attachment-full size-full"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutHero;
