import React from "react";
import styled from "styled-components";

const FooterWrapper = styled.footer`
  background-color: ${({ baseColor }) => baseColor};

  #qodef-page-footer-top-area {
    background-color: ${({ baseColor }) => baseColor};
  }
`;

const Footer = ({ color = "#dcd2db" }) => {
  return (
    <FooterWrapper id="qodef-page-footer" role="contentinfo" baseColor={color}>
      <div id="qodef-page-footer-top-area">
        <div
          id="qodef-page-footer-top-area-inner"
          className="qodef-content-full-width"
        >
          <div className="qodef-grid qodef-layout--columns qodef-responsive--custom qodef-col-num--2 qodef-col-num--680--1 qodef-col-num--480--1">
            <div className="qodef-grid-inner clear">
              <div className="qodef-grid-item">
                <div
                  id="custom_html-2"
                  className="widget_text widget widget_custom_html"
                  data-area="qodef-footer-top-area-column-1"
                >
                  <div className="textwidget custom-html-widget">
                    STUDIO PMS
                  </div>
                </div>
                <div
                  id="text-2"
                  className="widget widget_text"
                  data-area="qodef-footer-top-area-column-1"
                >
                  {" "}
                  <div className="textwidget">
                    <div
                      style={{ marginTop: "-23px" }}
                      data-mce-style="marginTop: -23px;"
                    >
                      <h5>For your creative output.</h5>
                    </div>
                  </div>
                </div>
                <div
                  id="text-3"
                  className="widget widget_text"
                  data-area="qodef-footer-top-area-column-1"
                >
                  <div className="textwidget">
                    <div
                      style={{
                        fontSize: "11px",
                        fontWeight: "500",
                        marginTop: "20px",
                      }}
                      data-mce-style="fontSize: 11px; fontWeight: 500; marginTop: 20px;"
                    >
                      © 2021 Studio PMS by
                      <a
                        href="http://trivetechnology.com"
                        data-mce-href="http://trivetechnology.com"
                      >
                        Trive
                      </a>
                      , All Rights Reserved
                    </div>
                  </div>
                </div>
              </div>
              <div className="qodef-grid-item">
                <div
                  id="text-4"
                  className="widget widget_text"
                  data-area="qodef-footer-top-area-column-2"
                >
                  <h4 className="qodef-widget-title">Clients</h4>{" "}
                  <div className="textwidget">
                    <p>
                      <img
                        className="alignnone wp-image-13439 size-full"
                        src="/img/adidas-logo-png-white-images-12-e1650556829391.png"
                        alt=""
                        width="100"
                        height="75"
                      />{" "}
                      <img
                        className="alignnone wp-image-13440 size-full"
                        src="/img/Burberry-Logo-e1650557343878.png"
                        alt=""
                        width="100"
                        height="56"
                      />{" "}
                      <img
                        className="alignnone wp-image-13819 size-full"
                        src="/img/CM-logo_Utrecht-small_Tekengebied_1-e1650557388696.png"
                        alt=""
                        width="100"
                        height="125"
                      />{" "}
                      <img
                        className="alignnone wp-image-13820 size-full"
                        src="/img/Bijenkorf-e1650557410881.png"
                        alt=""
                        width="100"
                        height="28"
                      />{" "}
                      <img
                        className="alignnone wp-image-13443 size-full"
                        src="/img/logo-e1650557367144.png"
                        alt=""
                        width="100"
                        height="26"
                        data-wp-editing="1"
                      />
                    </p>
                  </div>
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </FooterWrapper>
  );
};

export default Footer;
