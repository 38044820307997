import React from "react";
import styled from "styled-components";

import Header from "../../components/layout/header/Header";
import Footer from "../../components/layout/footer/Footer";
import ProjectFooterNav from "./ProjecFooterNav";
import ProjectMedia from "./ProjectMedia";
import ProjectInfo from "./ProjectInfo";
import projects from "../../constant/projects";
import { Navigate, useParams } from "react-router-dom";
import Head from "../../components/layout/head/Head";
import { device } from "../../constant/breakpoints";

const pageColor = "#eef1ff";

/* .elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap{background-color:#818a91;color:#fff}.elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap{color:#818a91;border:3px solid;background-color:transparent}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap{margin-top:8px}.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap-letter{width:1em;height:1em}.elementor-widget-text-editor .elementor-drop-cap{float:left;text-align:center;line-height:1;font-size:50px}.elementor-widget-text-editor .elementor-drop-cap-letter{display:inline-block} */
const Wrapper = styled.div`
  background-color: ${pageColor};
  #qodef-page-content {
    overflow: hidden;
    min-height: 75vh;
  }
`;

const LayoutTemplate = styled.div`
  @media ${device.tablet} {
    overflow: hidden;
    height: 100vh;

    > .qodef-portfolio-info-holder,
    > .qodef-e-content-inner {
      overflow: auto;
    }
  }
`;

const PageOuter = styled.div`
  margin-top: 0px !important;
`;

const ProjectDetail = () => {
  document.body.style.backgroundColor = pageColor;
  const { slug } = useParams();

  const details = projects.find((item) => item.slug === slug);
  const detailIndex = projects.indexOf(details);

  const getNextItem = () => {
    const nextIndex = detailIndex + 1;
    let nextItem = null;

    if (nextIndex < projects.length - 1) {
      nextItem = projects[nextIndex];
    } else {
      nextItem = projects[0];
    }

    return nextItem;
  };

  const getPrevItem = () => {
    const prevIndex = detailIndex - 1;
    let prev = null;

    if (prevIndex >= 0) {
      prev = projects[prevIndex];
    }

    return prev;
  };

  if (!details) {
    return <Navigate to="/not-found" replace />;
  }

  return (
    <Wrapper id="qodef-page-wrapper">
      <Head title={details.slug}>{details.title}</Head>
      <Header color={pageColor} />
      <>
        <PageOuter id="qodef-page-outer" style={{ paddingTop: "0px" }}>
          <div id="qodef-page-inner" className="qodef-content-full-width">
            <div className="qodef-content-full-width">
              <main
                id="qodef-page-content"
                className="qodef-grid qodef-layout--template "
                role="main"
              >
                <div className="qodef-grid-inner clear">
                  <div className="qodef-grid-item qodef-page-content-section qodef-col--12">
                    <div className="qodef-portfolio qodef-m qodef-portfolio-single qodef-layout--split-screen">
                      <article className="qodef-portfolio-single-item qodef-variations--small qodef-e post-1345 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-art portfolio-category-collage portfolio-tag-creative">
                        {/* {details.poster && (
                          <video
                            className="attachment-full size-full"
                            width="100%"
                            style={{
                              height: "100vh",
                              width: "100vw",
                              objectFit: "cover",
                            }}
                            src={details.poster}
                            autoPlay
                            loop
                            muted
                          />
                        )} */}
                        <div className="qodef-e-inner">
                          <LayoutTemplate className="qodef-e-content qodef-layout--template">
                            <ProjectInfo
                              title={details.title}
                              videoUrl={details.videoUrl}
                              metaData={details.metaData}
                            >
                              {details.content}
                            </ProjectInfo>
                            {details.gallery && (
                              <ProjectMedia mediaItems={details.gallery} />
                            )}
                          </LayoutTemplate>
                        </div>
                      </article>
                    </div>
                  </div>
                </div>
              </main>
            </div>
            <ProjectFooterNav
              color={pageColor}
              previous={getPrevItem()}
              next={getNextItem()}
            />
          </div>
        </PageOuter>
      </>
      <Footer color={pageColor} />
    </Wrapper>
  );
};

export default ProjectDetail;
