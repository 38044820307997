export const gallery = [
  {
    href: "/img/burrberry/BURB-AFB-1.jpg",
    title: "Burrberry",
    width: "800",
    height: "1067",
    src: "/img/burrberry/BURB-AFB-1.jpg",
  },
  {
    href: "/img/burrberry/BURB-VIDEO-1.mp4",
    title: "Burrberry",
    width: "800",
    height: "1067",
    type: "video",
    src: "/img/burrberry/BURB-VIDEO-1.mp4",
  },
  {
    href: "/img/burrberry/BURB-AFB-2.jpeg",
    title: "Burrberry",
    width: "800",
    height: "1067",
    src: "/img/burrberry/BURB-AFB-2.jpeg",
  },
  {
    href: "/img/burrberry/BURB-AFB-3.jpg",
    title: "Burrberry",
    width: "800",
    height: "1067",
    src: "/img/burrberry/BURB-AFB-3.jpg",
  },
  {
    href: "/img/burrberry/BURB-AFB-4.jpg",
    title: "Burrberry",
    width: "800",
    height: "1067",
    src: "/img/burrberry/BURB-AFB-4.jpg",
  },
];
