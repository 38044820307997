import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  z-index: 9999;
  background-color: ${({ color }) => color};
`;

const ProjectFooterNav = ({ color, next, previous }) => {
  if (!next && !previous) {
    return null;
  }

  return (
    <Wrapper
      color={color}
      id="qodef-single-portfolio-navigation"
      className="qodef-m"
    >
      <div className="qodef-m-inner">
        {previous && (
          <div className="qodef-m-nav qodef--prev">
            <div className="qodef-m-image">
              <a itemProp="url" href={previous.slug}>
                {previous.videoSrc && (
                  <video
                    className="attachment-178x178 size-178x178 wp-post-image"
                    width="134"
                    height="178"
                    src={previous.videoSrc}
                    autoPlay
                    loop
                    muted
                    loading="lazy"
                  />
                )}
                {previous.imgSrc && (
                  <img
                    width="134"
                    height="178"
                    src={previous.imgSrc}
                    className="attachment-178x178 size-178x178 wp-post-image"
                    alt={previous.title}
                    loading="lazy"
                  />
                )}
              </a>
            </div>
            <a itemProp="url" href={previous.slug}>
              <span className="qodef-m-nav-label">Previous project</span>
            </a>
          </div>
        )}
        {next && (
          <div className="qodef-m-nav qodef--next">
            <div className="qodef-m-image">
              <a itemProp="url" href={next.slug}>
                {next.videoSrc && (
                  <video
                    className="attachment-178x178 size-178x178 wp-post-image"
                    width="134"
                    height="178"
                    src={next.videoSrc}
                    autoPlay
                    loop
                    muted
                    loading="lazy"
                  />
                )}
                {next.imgSrc && (
                  <img
                    width="134"
                    height="178"
                    src={next.imgSrc}
                    className="attachment-178x178 size-178x178 wp-post-image"
                    alt={next.title}
                    loading="lazy"
                  />
                )}
              </a>
            </div>
            <a itemProp="url" href={next.slug}>
              <span className="qodef-m-nav-label">Next project</span>
            </a>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default ProjectFooterNav;
