// globalStyles.js
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

a, p a {
  text-decoration: none;
}

/* Bold */
@font-face {
  font-family: "Hatton";
  src: url("/fonts/Hatton/Hatton-Bold.eot"); /* IE9 Compat Modes */
  src: url("/fonts/Hatton/Hatton-Bold.eot")
      format("embedded-opentype"),
    url("/fonts/Hatton/Hatton-Bold.woff2")
      format("woff2"),
    url("/fonts/Hatton/Hatton-Bold.woff")
      format("woff"),
    url("/fonts/Hatton/Hatton-Bold.tff")
      format("truetype");
  font-weight: bold;
  font-style: normal;
}

/* Light  */
@font-face {
  font-family: "Hatton";
  src: url("/fonts/Hatton/Hatton-Light.eot"); /* IE9 Compat Modes */
  src: url("/fonts/Hatton/Hatton-Light.eot")
      format("embedded-opentype"),
    url("/fonts/Hatton/Hatton-Light.woff2")
      format("woff2"),
    url("/fonts/Hatton/Hatton-Light.woff")
      format("woff"),
    url("/fonts/Hatton/Hatton-Light.tff")
      format("truetype");
  font-weight: 300;
  font-style: normal;
}

/* Medium  */
@font-face {
  font-family: "Hatton";
  src: url("/fonts/Hatton/Hatton-Medium.eot"); /* IE9 Compat Modes */
  src: url("/fonts/Hatton/Hatton-Medium.eot")
      format("embedded-opentype"),
    url("/fonts/Hatton/Hatton-Medium.woff2")
      format("woff2"),
    url("/fonts/Hatton/Hatton-Medium.woff")
      format("woff"),
    url("/fonts/Hatton/Hatton-Medium.tff")
      format("truetype");
  font-weight: 500;
  font-style: normal;
}
/* Ultrabold  */
@font-face {
  font-family: "Hatton";
  src: url("/fonts/Hatton/Hatton-Ultrabold.eot"); /* IE9 Compat Modes */
  src: url("/fonts/Hatton/Hatton-Ultrabold.eot")
      format("embedded-opentype"),
    url("/fonts/Hatton/Hatton-Ultrabold.woff2")
      format("woff2"),
    url("/fonts/Hatton/Hatton-Ultrabold.woff")
      format("woff"),
    url("/fonts/Hatton/Hatton-Ultrabold.tff")
      format("truetype");
  font-weight: 800;
  font-style: normal;
}
/* Ultralight  */
@font-face {
  font-family: "Hatton";
  src: url("/fonts/Hatton/Hatton-Ultralight.eot"); /* IE9 Compat Modes */
  src: url("/fonts/Hatton/Hatton-Ultralight.eot")
      format("embedded-opentype"),
    url("/fonts/Hatton/Hatton-Ultralight.woff2")
      format("woff2"),
    url("/fonts/Hatton/Hatton-Ultralight.woff")
      format("woff"),
    url("/fonts/Hatton/Hatton-Ultralight.tff")
      format("truetype");
  font-weight: 200;
  font-style: normal;
}

.qodef-e-info > *,
h1,
.qodef-h1,
h2,
.qodef-h2,
h3,
.qodef-h3,
h4,
.qodef-h4,
h5,
.qodef-h5,
h6,
.qodef-h6,
blockquote,
.wp-block-quote,
input[type="submit"],
button[type="submit"],
.qodef-theme-button,
.mfp-bottom-bar .mfp-title,
.mfp-bottom-bar .mfp-counter,
.qodef-blog .qodef-filter-holder .qodef-ordering-items ul li,
#qodef-page-comments-list .qodef-comment-item .qodef-e-links > *,
.qodef-m-filter .qodef-m-filter-item,
#qodef-page-footer .widget.widget_block .wp-block-group__inner-container > h1,
#qodef-page-footer .widget.widget_block .wp-block-group__inner-container > h2,
#qodef-page-footer .widget.widget_block .wp-block-group__inner-container > h3,
#qodef-page-footer .widget.widget_block .wp-block-group__inner-container > h4,
#qodef-page-footer .widget.widget_block .wp-block-group__inner-container > h5,
#qodef-page-footer .widget.widget_block .wp-block-group__inner-container > h6,
#qodef-page-footer .qodef-widget-title,
.wp-block-button .wp-block-button__link,
.qodef-mobile-header-navigation ul li ul li > a,
.qodef-mobile-header-navigation > ul > li > a,
.qodef-header-navigation > ul > li.qodef-menu-item--narrow ul li > a,
.qodef-single-links .qodef-m-single-links-title,
.widget.widget_rss ul .rsswidget {
  font-family: "Hatton", "Syne", sans-serif;
}


			/* General */
      .qodef-info-section.qodef-layout--default.qodef-layout--full-height {
	height: calc(100vh - 59px) !important;
}

.grecaptcha-badge {
    display: none;
}

.qodef-mobile-header-opener .qodef-custom-svg-open {
	stroke: black !important;
}

#qodef-side-area {
	background-color: #f1e3e0;
}

.qodef-header--standard #qodef-page-header .qodef-logo-border {
	height: 100%
}
.qodef-header-sticky {
	background-color: inherit
}

/* Laurits inline style  */
#qodef-page-outer { margin-top: -60px;}@media only screen and (max-width: 1024px) { #qodef-page-outer { margin-top: -70px;}}#qodef-page-footer-top-area { background-color: #dcd2db;}body { background-color: #dcd2db;}#qodef-page-inner { padding: 0px 0px 0px 0px;}@media only screen and (max-width: 1024px) { #qodef-page-inner { padding: 0px 0px 0px 0px;}}.qodef-header-sticky { background-color: #fffdf7;}#qodef-page-header .qodef-header-logo-link.qodef-source--svg-path svg { width: 85px;}#qodef-page-mobile-header .qodef-mobile-header-opener { color: #dcd2db;}#qodef-page-mobile-header .qodef-mobile-header-logo-link.qodef-source--svg-path svg { width: 85px;}.qodef-page-title { background-color: #fffdf7;}.qodef-page-title .qodef-m-content { padding-top: 60px;}@media only screen and (max-width: 1024px) { .qodef-page-title .qodef-m-content { padding-top: 70px;}}.qodef-header--standard #qodef-page-header { background-color: #dcd2db;}.qodef-header--standard #qodef-page-header-inner { border-bottom-color: #7f8678;border-bottom-width: 1px;}.qodef-mobile-header--standard #qodef-mobile-header-navigation .qodef-m-inner { background-color: #dcd2db;}.qodef-mobile-header--standard #qodef-page-mobile-header { background-color: #dcd2db;}

/* Page styling */

/* Home */
.page-main-home.qodef-mobile-header--standard #qodef-page-mobile-header {
	background-color: #dcd2db;
}
.page-main-home .qodef-mobile-header-navigation {
	background-color:  #dcd2db;
}

.page-main-home #qodef-page-header *, .page-main-home #qodef-page-mobile-header .qodef-mobile-header-logo-link.qodef-source--textual {
	color: #3F3551 !important;
}
.page-main-home footer * {
	color: #3F3551 !important;
}
.page-main-home .qodef-mobile-header-opener .qodef-custom-svg-open {
	stroke: #3F3551 !important;
}

/* About Us */
.page-about-us.qodef-mobile-header--standard #qodef-page-mobile-header {
	background-color: #f3eac3;
}
.page-about-us .qodef-mobile-header-navigation {
	background-color:  #f3eac3;
}

.page-about-us #qodef-page-header *, .page-about-us #qodef-page-mobile-header .qodef-mobile-header-logo-link.qodef-source--textual  {
	color: #93421D !important;
}
.page-about-us #qodef-page-outer * {
	color: #93421D !important;
}
.page-about-us #qodef-page-outer .qodef-m-tagline::before {
	border-color:  #93421D
}
.page-about-us #qodef-page-outer svg circle {
	stroke: #93421D
}
.page-about-us footer * {
	color: #93421D !important;
}

.page-about-us .qodef-mobile-header-opener .qodef-custom-svg-open {
	stroke: #93421D !important;
}

.page-about-us.qodef-header--standard #qodef-page-header-inner,
.page-about-us.qodef-header--standard #qodef-page-header .qodef-logo-border,
.page-about-us.qodef-header--standard .widget_laurits_core_side_area_opener .qodef-widget-border, 
.page-about-us #qodef-page-mobile-header-inner {
	border-color: black;
}


/* Projects */
.single.single-portfolio-item.qodef-mobile-header--standard #qodef-page-mobile-header,
.page-projects.qodef-mobile-header--standard #qodef-page-mobile-header {
	background-color: #eef1ff
}

.single.single-portfolio-item .qodef-mobile-header-navigation ,
.page-projects .qodef-mobile-header-navigation {
	background-color:  #eef1ff
}

.single.single-portfolio-item #qodef-page-header *, #qodef-page-mobile-header .qodef-mobile-header-logo-link.qodef-source--textual,
.single.single-portfolio-item 
.page-projects #qodef-page-header *, .page-projects #qodef-page-mobile-header .qodef-mobile-header-logo-link.qodef-source--textual  {
	color: #354053 !important;
}
.page-projects * {
	color: #354053;
}

.single.single-portfolio-item .qodef-mobile-header-opener .qodef-custom-svg-open,
.page-projects .qodef-mobile-header-opener .qodef-custom-svg-open {
	stroke: #354053 !important;
}


.page-projects footer * {
	color: #354053 !important;
}

/* Single Portfolio */
.single.single-portfolio-item #qodef-page-header *,
.single.single-portfolio-item #qodef-single-portfolio-navigation *,
.single.single-portfolio-item #qodef-page-footer * {
	color: #354053 !important;
}

.single.single-portfolio-item #qodef-page-content h1,
.single.single-portfolio-item #qodef-page-content h2,
.single.single-portfolio-item #qodef-page-content h3,
.single.single-portfolio-item #qodef-page-content h4,
.single.single-portfolio-item #qodef-page-content h5,
.single.single-portfolio-item #qodef-page-content h6,
.single.single-portfolio-item #qodef-page-content p,
.single.single-portfolio-item #qodef-page-content span,
.single.single-portfolio-item a.elementor-button {
	color: #354053 !important;
}

.single.single-portfolio-item qodef-m-nav-label::after {
	background-color: #354053 !important;
}

/* Home Image slider */
.qodef-image-gallery.qodef-swiper-container.qodef-full-height .qodef-e {
	padding: 0 !important;

}

.qodef-image-gallery.qodef-swiper-container.qodef-full-height .qodef-e img {
		width: 100% !important;
	height: 100% !important;
	object-fit: cover;
}

.qodef-image-gallery.qodef-appear-animation--yes.qodef-col-num--1.qodef--appeared .qodef-image-wrapper img  {
	transform: translatey(25px);
}


/* Footer */
#qodef-page-footer-top-area-inner .qodef-grid-item:nth-of-type(2) .textwidget p {
	display: flex;
	align-items: center;
	justify-content: space-between
}

#qodef-page-footer-top-area-inner .qodef-grid-item:nth-of-type(2) .textwidget p img {
	width: 100px;
	height: auto;
}

.wpcf7 input, .wpcf7 textarea {
	border-bottom-color: black
}

.page-about-us #qodef-page-footer-top-area-inner .qodef-grid-item {
	border-color: black
}

.portfolio-item-template-default.single .qodef-e.qodef-inof--social-share {
	display: none
}

.portfolio-item-template-default.single .elementor-widget-wrap {
	padding-left: 0;
}

@media (max-width: 1024px) {
	#qodef-page-footer-top-area-inner .qodef-grid-item:nth-of-type(2) .textwidget p {
		flex-wrap: wrap;
	}

#qodef-page-footer-top-area-inner .qodef-grid-item:nth-of-type(2) .textwidget p img {
		margin: auto;
		flex: 0 0 33%;
	}
}		

#qodef-single-portfolio-navigation {
  margin-top: 0;
}

#qodef-page-wrapper {
  overflow: visible;
}

`;

export default GlobalStyle;
