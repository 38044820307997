import React from "react";
import styled from "styled-components";
import MobileMenuItem from "./MobileMenuItem";

const Menu = styled.nav`
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  width: 100%;
  max-height: calc(100vh - 70px);
  background-color: #fffdf7;
  border-bottom: 1px solid #7f8678;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 10;

  > ul {
    margin: 0 auto;
    padding: 34px 33px;
    list-style: none;
  }

  &.menu-open {
    display: block;
  }
`;

const MobileMenu = ({ isOpen }) => {
  return (
    <Menu
      className={`qodef-mobile-header-navigation ${isOpen ? "menu-open" : ""}`}
      role="navigation"
      aria-label="Mobile Menu"
    >
      <ul id="menu-main-menu-3" className="qodef-content-grid">
        <MobileMenuItem isSelected url="/">
          Home
        </MobileMenuItem>
        <MobileMenuItem url="/about-us/">About Us</MobileMenuItem>
        <MobileMenuItem url="/projects/">Projects</MobileMenuItem>
      </ul>
    </Menu>
  );
};

export default MobileMenu;
