import React, { useState } from "react";
import styled from "styled-components";
import { device } from "../../../constant/breakpoints";

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

const FormWrapper = styled.section`
  padding: 27px 33px 130px 33px;
  position: relative;
  overflow: visible;

  .elementor-column-gap-default {
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    .elementor-row {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      .elementor-column {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        width: 100%;
        min-height: 1px;
        .elementor-column-wrap {
          width: 100%;
          .elementor-widget-wrap {
            position: relative;
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
            .elementor-widget-wp-widget-laurits_core_contact_form_7 {
              width: 975px;
              max-width: 975px;
              margin: 0 auto;
              position: relative;

              .qodef-button-holder button {
                &:hover {
                  background-color: transparent;
                }
              }
            }
          }
        }
      }
    }
  }

  @media ${device.laptop} {
    padding: 27px 0px 130px 0px;
  }
`;

const Form = () => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();
  const [errors, setErrors] = useState();
  const [isValid, setIsValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [response, setResponse] = useState();

  const validateForm = () => {
    let errors = {};

    if (!name) {
      errors.name = true;
    }
    if (!email) {
      errors.email = true;
    }

    setErrors(errors);

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    const errors = validateForm();

    if (Object.values(errors).length > 0) {
      return;
    }

    try {
      const res = fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact", name, email, message }),
      });

      const data = res.json();

      console.log(data);

      setIsValid(true);
      setResponse("Thank you for submitting the contact form!");
    } catch (error) {
      setIsValid(false);
      setResponse(
        "There was an error while submitting the form, use info@studiopms.nl instead"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (stateSetter) => {
    return (e) => stateSetter(e.target.value);
  };

  return (
    <FormWrapper
      className="elementor-section elementor-top-section elementor-element elementor-element-40e760dc elementor-section-full_width elementor-section-height-default elementor-section-height-default qodef-backgound-letter-holder-no qodef-elementor-content-no"
      data-id="40e760dc"
      data-element_type="section"
      style={{ overflow: "visible" }}
    >
      <div className="elementor-container elementor-column-gap-default">
        <div className="elementor-row">
          <div
            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-a16e60a"
            data-id="a16e60a"
            data-element_type="column"
          >
            <div className="elementor-column-wrap elementor-element-populated">
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-166aaa4c elementor-widget__width-initial elementor-widget elementor-widget-wp-widget-laurits_core_contact_form_7"
                  data-id="166aaa4c"
                  data-element_type="widget"
                  data-widget_type="wp-widget-laurits_core_contact_form_7.default"
                >
                  <div className="elementor-widget-container">
                    <div className="qodef-contact-form-7">
                      <div
                        role="form"
                        className="wpcf7"
                        id="wpcf7-f14693-p4826-o1"
                        lang="en-US"
                        dir="ltr"
                      >
                        <div className="screen-reader-response">
                          <p
                            role="status"
                            aria-live="polite"
                            aria-atomic="true"
                          ></p>{" "}
                          <ul></ul>
                        </div>
                        <form
                          onSubmit={handleSubmit}
                          method="post"
                          className={`wpcf7-form ${
                            isSubmitting ? "submitting" : "init"
                          } ${isValid ? "valid" : "invalid"}`}
                          noValidate
                          data-status="init"
                          netlify="true"
                          name="pms_contact_form"
                          data-netlify="true"
                          data-netlify-honeypot="bot-field"
                        >
                          <input
                            type="hidden"
                            name="form-name"
                            value="pms_contact_form"
                          />

                          <div className="qodef-secondary-form">
                            <div className="qodef-input-holder">
                              <span className="wpcf7-form-control-wrap your-name">
                                <input
                                  type="text"
                                  name="your-name"
                                  value={name}
                                  size="40"
                                  className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                  aria-required="true"
                                  required
                                  aria-invalid="false"
                                  onChange={handleChange(setName)}
                                  placeholder="Your Name"
                                />
                                {errors?.name && (
                                  <span
                                    className="wpcf7-not-valid-tip"
                                    aria-hidden="true"
                                  >
                                    The field is required.
                                  </span>
                                )}
                              </span>
                              <span className="wpcf7-form-control-wrap your-email">
                                <input
                                  type="email"
                                  name="your-email"
                                  value={email}
                                  size="40"
                                  className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                  aria-required="true"
                                  required
                                  aria-invalid="false"
                                  onChange={handleChange(setEmail)}
                                  placeholder="Your Email"
                                />
                                {errors?.email && (
                                  <span
                                    className="wpcf7-not-valid-tip"
                                    aria-hidden="true"
                                  >
                                    The field is required.
                                  </span>
                                )}
                              </span>
                            </div>
                            <div className="qodef-button-holder">
                              <span className="wpcf7-form-control-wrap your-message">
                                <textarea
                                  name="your-message"
                                  cols="40"
                                  rows="10"
                                  value={message}
                                  className="wpcf7-form-control wpcf7-textarea"
                                  aria-invalid="false"
                                  placeholder="Message"
                                  onChange={handleChange(setMessage)}
                                ></textarea>
                              </span>
                              <button
                                type="submit"
                                className="wpcf7-form-control wpcf7-submit qodef-button qodef-size--normal qodef-layout--textual qodef-m"
                              >
                                <span className="qodef-m-text">Submit</span>
                              </button>
                            </div>
                          </div>
                          <div
                            className="wpcf7-response-output"
                            aria-hidden="true"
                          >
                            {response}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FormWrapper>
  );
};

export default Form;
