import React from "react";
import styled from "styled-components";
import { ListCircleIcon } from "../../../components/data-display/list-icon/ListCircleIcon";
import { device } from "../../../constant/breakpoints";

const Wrapper = styled.div`
  width: 100%;

  .elementor-widget-wrap {
    padding: 0% 12% 0% 0%;
  }

  @media ${device.laptop} {
    width: 40%;
    padding: 0;
    margin: 0;
  }
`;

export const Biography = () => (
  <Wrapper
    className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-8c762c5"
    data-id="8c762c5"
    data-element_type="column"
  >
    <div className="elementor-widget-wrap elementor-element-populated">
      <div
        className="elementor-element elementor-element-a607a5c elementor-widget elementor-widget-laurits_core_icon_with_text"
        data-id="a607a5c"
        data-element_type="widget"
        data-widget_type="laurits_core_icon_with_text.default"
      >
        <div className="elementor-widget-container">
          <div className="qodef-shortcode qodef-m  qodef-icon-with-text qodef-layout--before-title qodef--custom-icon  ">
            <div className="qodef-m-content">
              <h4 className="qodef-m-title">
                <span className="qodef-m-title-inner">
                  <ListCircleIcon />
                  <span className="qodef-m-title-text">Biography </span>
                </span>
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div
        className="elementor-element elementor-element-2167f19 elementor-widget elementor-widget-text-editor"
        data-id="2167f19"
        data-element_type="widget"
        data-widget_type="text-editor.default"
      >
        <div className="elementor-widget-container">
          <p>
            <span style={{ fontWeight: "400" }}>
              Studio PMS is a digital design collective: w
            </span>
            <span style={{ fontWeight: "400" }}>
              e are three innovative creatives specialized in creative
              direction, production and consultancy.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              While always striving for a more sustainable design industry,
              Studio PMS has a strong affinity with interdisciplinary projects
              using innovative techniques. These can vary up to 3D modeling,
              animation, hologrammes, virtual reality or augmented reality.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              As a digital consultant and a creative studio we collaborate with
              international, exciting and innovative brands. We never stop
              exploring the possibilities and purposes that digital fashion can
              have, whilst bridging the gap between physical and virtual.
            </span>
          </p>{" "}
        </div>
      </div>
    </div>
  </Wrapper>
);
