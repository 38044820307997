export const gallery = [
  {
    href: "/img/ADIDAS-HYPEBAE/PMS-ADIDAS-HYPEBAE-01.gif",
    title: "PMS-ADIDAS-HYPEBAE-01",
    width: "800",
    height: "1067",
    src: "/img/ADIDAS-HYPEBAE/PMS-ADIDAS-HYPEBAE-01.gif",
  },

  {
    href: "/img/ADIDAS-HYPEBAE/PMS-ADIDAS-HYPEBAE-02-scaled.jpg",
    title: "PMS-ADIDAS-HYPEBAE-02",
    width: "1802",
    height: "2560",
    src: "/img/ADIDAS-HYPEBAE/PMS-ADIDAS-HYPEBAE-02-scaled.jpg",
  },

  {
    href: "/img/ADIDAS-HYPEBAE/PMS-ADIDAS-HYPEBAE-03.gif",
    title: "PMS-ADIDAS-HYPEBAE-03",
    width: "800",
    height: "1067",
    src: "/img/ADIDAS-HYPEBAE/PMS-ADIDAS-HYPEBAE-03.gif",
  },

  {
    href: "/img/ADIDAS-HYPEBAE/PMS-ADIDAS-HYPEBAE-04-scaled.jpg",
    title: "PMS-ADIDAS-HYPEBAE-04",
    width: "1802",
    height: "2560",
    src: "/img/ADIDAS-HYPEBAE/PMS-ADIDAS-HYPEBAE-04-scaled.jpg",
  },

  {
    href: "/img/ADIDAS-HYPEBAE/PMS-ADIDAS-HYPEBAE-05.gif",
    title: "PMS-ADIDAS-HYPEBAE-05",
    width: "800",
    height: "1067",
    src: "/img/ADIDAS-HYPEBAE/PMS-ADIDAS-HYPEBAE-05.gif",
  },
];
