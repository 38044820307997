import React from "react";
import {
  aparrellelExistance,
  inDeMaak,
  bijenkorfTrends,
  adidasHypebaePms,
  burberry,
  helsinkiFashionWeek,
  kassl,
  centralMuseum,
} from "./project-gallery";

const projects = [
  {
    slug: "aparrellel-existance",
    imgSrc: "/img/aparrellel-existance/lekker.png",
    title: "Aparrellel Existance",
    videoUrl: "https://player.vimeo.com/video/397467428",
    metaData: [
      { key: "client", value: "Frame X Bolon" },
      { key: "type", value: "Augmented Technology" },
    ],
    content: (
      <>
        <p>
          <span style={{ fontWeight: "400" }}>
            A parallel Existence is the result of a{" "}
          </span>
          <span style={{ fontWeight: "400" }}>design challenge</span>{" "}
          <span style={{ fontWeight: "400" }}>
            by Frame in collaboration with Bolon.&nbsp;&nbsp;
          </span>
        </p>
        <p>
          <span style={{ fontWeight: "400" }}>
            Through an immersive experience based on hyper-personalization,
            Studio PMS used augmented technology to hint at ways to produce less
            without denying customers their sense of choice. After downloading
            the Studio PMS app, participants are invited to select one of three
            unique digital realms. While wandering around the physical space
            (designed in collaboration with Attach Studio) and clad in Bolon
            materials, viewers start to see figures dressed in digital fashion
            featuring Bolon prints – but only through their handheld screen.
          </span>
        </p>
        <p>
          In our work, Studio PMS has often looked for innovative and
          alternative ways of communicating fashion. In addition to the
          still-dominant overproduction, we strive for a fashion industry that
          is less hierarchical, conservative, and significantly less passive
          towards its audience.
        </p>{" "}
      </>
    ),
    gallery: aparrellelExistance,
  },
  {
    slug: "in-de-maak",
    videoSrc: "/img/in-de-maak/in-de-maak.mp4",
    title: "In de maak",
    metaData: [
      { key: "client", value: "Room on the Roof" },
      { key: "type", value: "Digitization" },
    ],
    content: (
      <>
        <p>
          <span style={{ fontWeight: "400" }}>
            Het Zondagskind is Studio PMS’ participation of In de Maak (In the
            Making), a fashion exhibition by{" "}
          </span>
          <span style={{ fontWeight: "400" }}>Room on the Roof </span>
          <span style={{ fontWeight: "400" }}>for </span>
          <span style={{ fontWeight: "400" }}>De Bijenkorf in 2021</span>
          <span style={{ fontWeight: "400" }}>
            {" "}
            in all of the store’s windows.&nbsp;
          </span>
        </p>
        <p>
          <span style={{ fontWeight: "400" }}>
            Studio PMS takes part in the ‘Makeable Future’ window, besides{" "}
          </span>
          <span style={{ fontWeight: "400" }}>Margherita Soldati </span>
          <span style={{ fontWeight: "400" }}>and </span>
          <span style={{ fontWeight: "400" }}>Armia Yousefi</span>
          <span style={{ fontWeight: "400" }}>
            . Reuse, digitization and use of natural materials are important
            answers for a more sustainable future and we were chosen to
            represent one of the three elements.
          </span>
        </p>
        <p>
          <span style={{ fontWeight: "400" }}>
            The exhibition traveled through de Bijenkorf’s fashion history and
            stages a fashion landscape of tomorrow. This celebration of
            creativity invited passers-by to get inspired and revalue
            craftsmanship. In de Maak traveled to all seven de Bijenkorf stores
          </span>
        </p>
      </>
    ),
    gallery: inDeMaak,
  },
  {
    slug: "bijenkorf-ss22-trends",
    videoSrc:
      "https://res.cloudinary.com/studio-pms/video/upload/v1659085312/bijenkorf-ss22-trends/trends_hctojg.mp4",
    imgSrcSet: [],
    title: "Bijenkorf SS22 Trends",
    metaData: [
      { key: "client", value: "Creative Office Bijenkorf" },
      { key: "type", value: "Visual Input" },
    ],
    content: (
      <p>
        <span style={{ fontWeight: "400" }}>
          Design and animations for de Bijenkorf’s bi-annual trend forecast
          presentations. These presentations serve as visual input for the
          future strategies of the Dutch department store. Studio PMS creates a
          colour and material palette that captures the future focus for the
          buying department.&nbsp;
        </span>
      </p>
    ),
    gallery: bijenkorfTrends,
  },
  {
    slug: "adidas-hypebae-pms",
    imgSrc:
      "/img/ADIDAS-HYPEBAE/Kopie-van-Kopie-van-bright-contrast-final-scaled.jpg",
    title: "Adidas x Hypebae x PMS",
    videoUrl:
      "https://player.vimeo.com/video/693549729?autoplay=1&playsinline=1&color&autopause=0&loop=1&muted=0&title=1&portrait=1&byline=1&h=858e9b2a6e#t=",
    metaData: [
      { key: "client", value: "HYPEBAE" },
      { key: "type", value: "Virtual Runway" },
    ],
    content: (
      <p>
        <span style={{ fontWeight: "400" }}>Partnering with </span>
        <span style={{ fontWeight: "400" }}>HYPEBAE</span>
        <span style={{ fontWeight: "400" }}>
          {" "}
          , Studio PMS has created a virtual runway featuring designs from{" "}
        </span>
        <span style={{ fontWeight: "400" }}>Adidas Originals</span>
        <span style={{ fontWeight: "400" }}>. </span>
        <span style={{ fontWeight: "400" }}>
          We wanted to show the endless possibilities and advantages of digital
          runways without losing the feeling that physical fashion can give you.
          A runway experience is, in our opinion, mostly created by how the
          garments move, how the fabric acts around the body, and the structure
          and details make you almost want to touch them. By combining iconic
          adidas pieces with our own outfits, we hope that there will be a
          cross-pollination that makes the outfits innovative yet accessible.
        </span>
      </p>
    ),
    gallery: adidasHypebaePms,
  },
  {
    slug: "burberry",
    imgSrc: "/img/burrberry/BURB-AFB-3.jpg",
    imgSrcSet: [],
    imgSizes: "",
    title: "Burberry",
    metaData: [{ key: "client", value: "Hypebeast" }],
    content: (
      <p>
        <span style={{ fontWeight: "400" }}>
          This time we joined forces with Hypebeast and Burberry to bring the
          new Thomas Burberry Monogram collection to life with 3d technology.
          The virtual garments are worn by Zhou Dongyu, fashion blogger Enkako,
          model and stylist Xie Yunpeng.
        </span>
      </p>
    ),
    gallery: burberry,
  },
  {
    slug: "helsinki-fashion-week",
    imgSrc: "/img/HFW/HFW-AFB-1.jpg",
    title: "Helsinki Fashion Week",
    metaData: [
      { key: "event", value: "Helsinki fashion week" },
      { key: "rendering", value: "Willems Works" },
      { key: "sound", value: "Antal van Nie" },
    ],
    content: (
      <p>
        <span style={{ fontWeight: "bold" }}>
          A designer residency collaboration with Open Plan during the first
          virtual-only Helsinki Fashion Week Official.
        </span>{" "}
        Ok Sun from Open Plan is devoted to designing plastic-free and vegan
        garments. During this collaboration, Studio PMS co-created a final look
        from Ok Sun’s newest collection and created some new audiovisual work to
        showcase during the fashion Week. In the video called Captis Nature,
        Studio PMS captured the tactility, sensitivity and the dynamics of
        nature in a digital world.
      </p>
    ),
    gallery: helsinkiFashionWeek,
  },
  {
    slug: "kassl-editions",
    poster:
      "https://res.cloudinary.com/studio-pms/video/upload/v1659346865/kassl/AFW_VIDEO_1_ucaxsq.mov",
    imgSrc:
      "https://res.cloudinary.com/studio-pms/image/upload/v1659346422/kassl/VoorlindenPMS_th5dbx.png",
    title: "Kassl Editions",
    metaData: [
      { key: "event", value: "Amsterdam Fashion Week 2020" },
      { key: "sound", value: "Antal van Nie" },
    ],
    content: (
      <p>
        <span style={{ fontWeight: "bold" }}>Amsterdam Fashion Week 2020</span>{" "}
        Curated by Bob Verhelst, ten other artists responded to the emerging
        Kassl universe from their own perspective at Kassl Edition’s guerilla
        exhibition at Amsterdam Fashion Week. The exhibition was held in
        Voorlinden Museum. The video installation Tight Lines, which refers to
        the traditional fisherman's greeting, was shown in the auditorium
      </p>
    ),
    gallery: kassl,
  },
  {
    slug: "pattern-to-polygon",
    imgSrc:
      "https://res.cloudinary.com/studio-pms/image/upload/v1659362341/centraal-museum/T202204_009_UL_Pasvorm_polygon__foto_CMU-AvD_wj1wvg.jpg",
    title: "From Pattern to Polygon",
    metaData: [
      { key: "design", value: "Renee Mes & Laura Schurink" },
      { key: "event", value: "Amsterdam Fashion Week 2020" },
      { key: "editor", value: "Ivan Hidayat" },
      { key: "sound", value: "Antal van Nie" },
    ],
    content: (
      <>
        <p>
          Virtual fashion shows and digital cover models: the dividing line
          between the physical and the virtual world is increasingly blurred. In
          the exhibition From Pattern to Polygon they take the fashion
          collection of the Centraal Museum as a starting point, to take a new
          look at fashion heritage. What possibilities do digital techniques
          offer to breathe new life into craft, knowledge and collection?
        </p>
        <p>
          From Pattern to Polygon is divided into three themes: Behind the
          Scenes, Step by Step and Adjustable. In the first room, BEHIND THE
          SCENES provides an insight into the digital design process. STEP BY
          STEP is the result of the digitized collection of the Centraal Museum:
          this transformation bridges the gap between the present and the past.
          For Adjustable Studio PMS works together with a new generation of
          designers. Together they go one step further: they reconstruct an item
          of clothing from the museum's extensive fashion collection and give it
          a new adaptation.
        </p>
        <p>
          The exhibition is the start of the Netherlands' first digital fashion
          archive, a multi-year project that we will develop further in the
          coming period in collaboration with Modemuze.
        </p>
      </>
    ),
    gallery: centralMuseum,
  },
  {
    slug: "coming-soon",
    videoSrc:
      "https://res.cloudinary.com/studio-pms/video/upload/v1659085300/coming-soon_qwn8ll.mp4",
    imgSrcSet: [],
    imgSizes: "",
    url: "/projects/coming-soon/",
    title: "Coming Soon",
    gallery: [
      {
        href: "https://res.cloudinary.com/studio-pms/video/upload/v1659085300/coming-soon_qwn8ll.mp4",
        title: "In de Maak",
        width: "800",
        height: "1067",
        type: "video",
        src: "https://res.cloudinary.com/studio-pms/video/upload/v1659085300/coming-soon_qwn8ll.mp4",
      },
    ],
  },
];

export default projects;
