export const gallery = [
  {
    href: "https://res.cloudinary.com/studio-pms/image/upload/v1659085661/bijenkorf-ss22-trends/Waarzegster-zoom-4-scaled_fo6bcd.jpg",
    title: "Waarzegster zoom 4",
    width: "1810",
    height: "2560",
    src: "https://res.cloudinary.com/studio-pms/image/upload/v1659085661/bijenkorf-ss22-trends/Waarzegster-zoom-4-scaled_fo6bcd.jpg",
  },
  // {
  //   href: "https://res.cloudinary.com/studio-pms/image/upload/v1659085660/bijenkorf-ss22-trends/waarzegster-zoom-3_adzzt1.jpg",
  //   title: "waarzegster zoom 3",
  //   width: "1559",
  //   height: "2079",
  //   src: "https://res.cloudinary.com/studio-pms/image/upload/v1659085660/bijenkorf-ss22-trends/waarzegster-zoom-3_adzzt1.jpg",
  // },
  {
    href: "https://res.cloudinary.com/studio-pms/image/upload/v1659085661/bijenkorf-ss22-trends/Waarzegster-zoom-2_rak7o4.jpg",
    title: "Waarzegster zoom 2",
    width: "1640",
    height: "2460",
    src: "https://res.cloudinary.com/studio-pms/image/upload/v1659085661/bijenkorf-ss22-trends/Waarzegster-zoom-2_rak7o4.jpg",
  },
  {
    href: "https://res.cloudinary.com/studio-pms/image/upload/v1659085660/bijenkorf-ss22-trends/waarzegster-zoom-1-scaled_yukcpv.jpg",
    title: "waarzegster zoom 1",
    width: "1814",
    height: "2560",
    src: "https://res.cloudinary.com/studio-pms/image/upload/v1659085660/bijenkorf-ss22-trends/waarzegster-zoom-1-scaled_yukcpv.jpg",
  },
  {
    href: "https://res.cloudinary.com/studio-pms/image/upload/v1659085660/bijenkorf-ss22-trends/Waarzegster-full-vieuw-scaled_sp77r2.jpg",
    title: "Waarzegster full vieuw",
    width: "1730",
    height: "2560",
    src: "https://res.cloudinary.com/studio-pms/image/upload/v1659085660/bijenkorf-ss22-trends/Waarzegster-full-vieuw-scaled_sp77r2.jpg",
  },
  // {
  //   href: "https://res.cloudinary.com/studio-pms/image/upload/v1659085660/bijenkorf-ss22-trends/Waarzegster-full-vieuw-2-scaled_e80sxo.jpg",
  //   title: "Waarzegster full vieuw 2",
  //   width: "1802",
  //   height: "2560",
  //   src: "https://res.cloudinary.com/studio-pms/image/upload/v1659085660/bijenkorf-ss22-trends/Waarzegster-full-vieuw-2-scaled_e80sxo.jpg",
  // },
  {
    href: "/img/bijenkorf-ss22-trends/jutter-zoom-5.png",
    title: "jutter zoom 5",
    width: "1203",
    height: "1702",
    src: "/img/bijenkorf-ss22-trends/jutter-zoom-5.png",
  },
  {
    href: "https://res.cloudinary.com/studio-pms/image/upload/v1659086206/bijenkorf-ss22-trends/jutter-zoom-4-e1650556302829_adkyqt.png",
    title: "jutter zoom 4",
    width: "1203",
    height: "1702",
    src: "https://res.cloudinary.com/studio-pms/image/upload/v1659086206/bijenkorf-ss22-trends/jutter-zoom-4-e1650556302829_adkyqt.png",
  },
  {
    href: "https://res.cloudinary.com/studio-pms/image/upload/v1659086206/bijenkorf-ss22-trends/jutter-zoom-3-e1650556284915_qggfkt.png",
    title: "jutter zoom 3",
    width: "1203",
    height: "1702",
    src: "https://res.cloudinary.com/studio-pms/image/upload/v1659086206/bijenkorf-ss22-trends/jutter-zoom-3-e1650556284915_qggfkt.png",
  },
  {
    href: "https://res.cloudinary.com/studio-pms/image/upload/v1659086205/bijenkorf-ss22-trends/jutter-zoom-2-e1650556236906_jlhwl1.png",
    title: "jutter zoom 2",
    width: "1203",
    height: "1702",
    src: "https://res.cloudinary.com/studio-pms/image/upload/v1659086205/bijenkorf-ss22-trends/jutter-zoom-2-e1650556236906_jlhwl1.png",
  },
  // {
  //   href: "https://res.cloudinary.com/studio-pms/image/upload/v1659086209/bijenkorf-ss22-trends/Jutter-zoem-1_rx5ynn.png",
  //   title: "Jutter zoem 1",
  //   width: "2476",
  //   height: "1651",
  //   src: "https://res.cloudinary.com/studio-pms/image/upload/v1659086209/bijenkorf-ss22-trends/Jutter-zoem-1_rx5ynn.png",
  // },
  {
    href: "https://res.cloudinary.com/studio-pms/image/upload/v1659086209/bijenkorf-ss22-trends/Jutter-full-vieuw_dafjgl.png",
    title: "Jutter full vieuw",
    width: "2461",
    height: "3474",
    src: "https://res.cloudinary.com/studio-pms/image/upload/v1659086209/bijenkorf-ss22-trends/Jutter-full-vieuw_dafjgl.png",
  },
];
