import React from "react";
import styled from "styled-components";
import { ListCircleIcon } from "../../../components/data-display/list-icon/ListCircleIcon";
import { device } from "../../../constant/breakpoints";

const Wrapper = styled.div`
  padding: 50px 0px 0px 0px;
  width: 100%;

  @media ${device.tablet} {
    width: 50%;
  }
  @media ${device.laptop} {
    width: 20%;
    padding: 0;
    margin: 0;
  }
`;

// const items = [
//   {
//     title: "Centraal Museum",
//     link: "https://www.centraalmuseum.nl/nl/tentoonstellingen/utrecht-lokaal-studio-pms",
//   },
//   {
//     title: "Dutch Design Week",
//     link: "https://ddw.nl/nl/programma/6674/pms-x-hypebae-x-adidas",
//   },
//   { title: "Bijenkorf", link: "http://roomontheroof.nl" },
// ];

export const Publications = () => {
  return (
    <Wrapper
      className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-655d3f1"
      data-id="655d3f1"
      data-element_type="column"
    >
      <div className="elementor-widget-wrap elementor-element-populated">
        <div
          className="elementor-element elementor-element-ac5dc57 elementor-widget__width-initial elementor-widget elementor-widget-laurits_core_icon_with_text"
          data-id="ac5dc57"
          data-element_type="widget"
          data-widget_type="laurits_core_icon_with_text.default"
        >
          <div className="elementor-widget-container">
            <div className="qodef-shortcode qodef-m  qodef-icon-with-text qodef-layout--before-title qodef--custom-icon  ">
              <div className="qodef-m-content">
                <h4 className="qodef-m-title">
                  <span className="qodef-m-title-inner">
                    <ListCircleIcon />
                    <span className="qodef-m-title-text">Publications </span>
                  </span>
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div
          className="elementor-element elementor-element-f75d487 elementor-widget__width-initial elementor-widget elementor-widget-text-editor"
          data-id="f75d487"
          data-element_type="widget"
          data-widget_type="text-editor.default"
        >
          <div className="elementor-widget-container">
            <p style={{ color: "#000000" }}>
              <a
                className="qodef-underline"
                href="https://www.volkskrant.nl/cultuur-media/in-het-centraal-museum-krijgen-stokoude-japonnen-een-tweede-leven-in-digitaal-3d~b7b1f7a4/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Volkskrant
              </a>
            </p>{" "}
          </div>
        </div>
        <div
          className="elementor-element elementor-element-f75d487 elementor-widget__width-initial elementor-widget elementor-widget-text-editor"
          data-id="f75d487"
          data-element_type="widget"
          data-widget_type="text-editor.default"
        >
          <div className="elementor-widget-container">
            <p style={{ color: "#000000" }}>
              <a
                className="qodef-underline"
                href="https://www.centraalmuseum.nl/nl/tentoonstellingen/utrecht-lokaal-studio-pms"
                target="_blank"
                rel="noopener noreferrer"
              >
                Centraal Museum
              </a>
            </p>{" "}
          </div>
        </div>
        <div
          className="elementor-element elementor-element-d6a0031 elementor-widget__width-initial elementor-widget elementor-widget-text-editor"
          data-id="d6a0031"
          data-element_type="widget"
          data-widget_type="text-editor.default"
        >
          <div className="elementor-widget-container">
            <p style={{ color: "#000000" }}>
              <a
                className="qodef-underline"
                href="https://ddw.nl/nl/programma/6674/pms-x-hypebae-x-adidas"
                target="_blank"
                rel="noopener noreferrer"
              >
                Dutch Design Week
              </a>
            </p>{" "}
          </div>
        </div>
        <div
          className="elementor-element elementor-element-d05005e elementor-widget__width-initial elementor-widget elementor-widget-text-editor"
          data-id="d05005e"
          data-element_type="widget"
          data-widget_type="text-editor.default"
        >
          <div className="elementor-widget-container">
            <p style={{ color: "#000000" }}>
              <a
                className="qodef-underline"
                href="http://roomontheroof.nl"
                target="_blank"
                rel="noopener noreferrer"
              >
                Bijenkorf
              </a>
            </p>{" "}
          </div>
        </div>
        <div
          className="elementor-element elementor-element-f2fcd5e elementor-widget__width-initial elementor-widget elementor-widget-text-editor"
          data-id="f2fcd5e"
          data-element_type="widget"
          data-widget_type="text-editor.default"
        >
          <div className="elementor-widget-container">
            <p style={{ color: "#000000" }}>
              <a
                className="qodef-underline"
                href="https://www.glamcult.com/articles/in-de-maak/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Glamcult
              </a>
            </p>{" "}
          </div>
        </div>
        <div
          className="elementor-element elementor-element-f6a88da elementor-widget__width-initial elementor-widget elementor-widget-text-editor"
          data-id="f6a88da"
          data-element_type="widget"
          data-widget_type="text-editor.default"
        >
          <div className="elementor-widget-container">
            <p>
              <a
                className="qodef-underline"
                href="https://nextnature.net/magazine/story/2021/interview-studio-pms"
                target="_blank"
                rel="noopener noreferrer"
              >
                Next Nature
              </a>
            </p>{" "}
          </div>
        </div>
        <div
          className="elementor-element elementor-element-4d19829 elementor-widget__width-initial elementor-widget elementor-widget-text-editor"
          data-id="4d19829"
          data-element_type="widget"
          data-widget_type="text-editor.default"
        >
          <div className="elementor-widget-container">
            <p style={{ color: "#000000" }}>
              <a
                className="qodef-underline"
                href="https://hypebae.com/2021/7/studio-pms-digital-fashion-design-collective-virtual-reality-interview"
                target="_blank"
                rel="noopener noreferrer"
              >
                Hypebea x Adidas
              </a>
            </p>{" "}
          </div>
        </div>
        <div
          className="elementor-element elementor-element-c3ec0af elementor-widget__width-initial elementor-widget elementor-widget-text-editor"
          data-id="c3ec0af"
          data-element_type="widget"
          data-widget_type="text-editor.default"
        >
          <div className="elementor-widget-container">
            <p style={{ color: "#000000" }}>
              <a
                className="qodef-underline"
                href="https://neworderoffashion.com/designers/studio-pms/"
                target="_blank"
                rel="noopener noreferrer"
              >
                New Order of Fashion
              </a>
            </p>{" "}
          </div>
        </div>
        <div
          className="elementor-element elementor-element-d037c4b elementor-widget__width-initial elementor-widget elementor-widget-text-editor"
          data-id="d037c4b"
          data-element_type="widget"
          data-widget_type="text-editor.default"
        >
          <div className="elementor-widget-container">
            <p style={{ color: "#000000" }}>
              <a
                className="qodef-underline"
                href="https://www.frameweb.com/article/how-digitizing-the-design-process-can-help-tackle-overproduction"
                target="_blank"
                rel="noopener noreferrer"
              >
                Frame
              </a>
            </p>{" "}
          </div>
        </div>
        <div
          className="elementor-element elementor-element-c247c9a elementor-widget__width-initial elementor-widget elementor-widget-text-editor"
          data-id="c247c9a"
          data-element_type="widget"
          data-widget_type="text-editor.default"
        >
          <div className="elementor-widget-container">
            <p style={{ color: "#000000" }}>
              <a
                className="qodef-underline"
                href="https://hypebeast.cn/2019/6/burberry-thomas-monogram-collection"
                target="_blank"
                rel="noopener noreferrer"
              >
                Burberry
              </a>
            </p>{" "}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
