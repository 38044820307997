export const gallery = [
  {
    href: "/img/aparrellel-existance/WhatsApp-Image-2019-11-01-at-15.21.10.jpeg",
    title: "WhatsApp Image 2019-11-01 at 15.21.10",
    width: "1131",
    height: "1600",
    src: "/img/aparrellel-existance/WhatsApp-Image-2019-11-01-at-15.21.10.jpeg",
  },
  // {
  //   href: "https://player.vimeo.com/video/397467428",
  //   title: "Helsinki Fashion Week",
  //   width: "800",
  //   height: "530",
  //   type: "vimeo",
  //   src: "https://player.vimeo.com/video/397467428",
  // },
  {
    href: "/img/aparrellel-existance/WhatsApp-Image-2019-10-11-at-13.40.16.jpeg",
    title: "WhatsApp Image 2019-10-11 at 13.40.16",
    width: "901",
    height: "1280",
    src: "/img/aparrellel-existance/WhatsApp-Image-2019-10-11-at-13.40.16.jpeg",
  },
  {
    href: "/img/aparrellel-existance/mail-800x.png",
    title: "mail-800x",
    width: "800",
    height: "446",
    src: "/img/aparrellel-existance/mail-800x.png",
  },
  {
    href: "/img/aparrellel-existance/Kopie-van-renderframebolonoutfuit5metstik_28-scaled.jpg",
    title: "Kopie van renderframebolonoutfuit5metstik_28",
    width: "1810",
    height: "2560",
    src: "/img/aparrellel-existance/Kopie-van-renderframebolonoutfuit5metstik_28-scaled.jpg",
  },
  {
    href: "/img/aparrellel-existance/knipper-800x.png",
    title: "knipper-800x",
    width: "800",
    height: "531",
    src: "/img/aparrellel-existance/knipper-800x.png",
  },
  {
    href: "/img/aparrellel-existance/esdefe-800x.jpg",
    title: "esdefe-800x",
    width: "620",
    height: "928",
    src: "/img/aparrellel-existance/esdefe-800x.jpg",
  },
  {
    href: "/img/aparrellel-existance/ENERGYZING1-scaled.jpg",
    title: "ENERGYZING(1)",
    width: "1811",
    height: "2560",
    src: "/img/aparrellel-existance/ENERGYZING1-scaled.jpg",
  },
  {
    href: "/img/aparrellel-existance/ENERGYZING-scaled.jpg",
    title: "ENERGYZING",
    width: "1811",
    height: "2560",
    src: "/img/aparrellel-existance/ENERGYZING-scaled.jpg",
  },
  {
    href: "/img/aparrellel-existance/energyzing_1-scaled.jpg",
    title: "energyzing_1",
    width: "1811",
    height: "2560",
    src: "/img/aparrellel-existance/energyzing_1-scaled.jpg",
  },
  {
    href: "/img/aparrellel-existance/calming_6-scaled.jpg",
    title: "calming_6",
    width: "1811",
    height: "2560",
    src: "/img/aparrellel-existance/calming_6-scaled.jpg",
  },
  {
    href: "/img/aparrellel-existance/calming_3-scaled.jpg",
    title: "calming_3",
    width: "1811",
    height: "2560",
    src: "/img/aparrellel-existance/calming_3-scaled.jpg",
  },
  {
    href: "/img/aparrellel-existance/calming_1-scaled.jpg",
    title: "calming_1",
    width: "1811",
    height: "2560",
    src: "/img/aparrellel-existance/calming_1-scaled.jpg",
  },
  {
    href: "/img/aparrellel-existance/CALMING-zoom-fender-frame_7-1-scaled.jpg",
    title: "CALMING zoom fender frame_7 (1)",
    width: "1811",
    height: "2560",
    src: "/img/aparrellel-existance/CALMING-zoom-fender-frame_7-1-scaled.jpg",
  },
  {
    href: "/img/aparrellel-existance/CALMING-zoom-fender-frame_5-scaled.jpg",
    title: "CALMING zoom fender frame_5",
    width: "1811",
    height: "2560",
    src: "/img/aparrellel-existance/CALMING-zoom-fender-frame_5-scaled.jpg",
  },
  {
    href: "/img/aparrellel-existance/CALMING-zoom-fender-frame_1-scaled.jpg",
    title: "CALMING zoom fender frame_1",
    width: "1811",
    height: "2560",
    src: "/img/aparrellel-existance/CALMING-zoom-fender-frame_1-scaled.jpg",
  },
  {
    href: "/img/aparrellel-existance/calming-outfit-2_1-scaled.jpg",
    title: "calming outfit 2_1",
    width: "1811",
    height: "2560",
    src: "/img/aparrellel-existance/calming-outfit-2_1-scaled.jpg",
  },
  {
    href: "/img/aparrellel-existance/ALIANATING2-scaled.jpg",
    title: "ALIANATING(2)",
    width: "1802",
    height: "2560",
    src: "/img/aparrellel-existance/ALIANATING2-scaled.jpg",
  },
  {
    href: "/img/aparrellel-existance/ALIANATING1-scaled.jpg",
    title: "ALIANATING(1)",
    width: "1811",
    height: "2560",
    src: "/img/aparrellel-existance/ALIANATING1-scaled.jpg",
  },
  {
    href: "/img/aparrellel-existance/ALIANATING-scaled.jpg",
    title: "ALIANATING",
    width: "1802",
    height: "2560",
    src: "/img/aparrellel-existance/ALIANATING-scaled.jpg",
  },
];
