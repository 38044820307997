import React from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";

import "react-modal-video/scss/modal-video.scss";
import App from "./components/App";

const root = document.getElementById("root");
render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  root
);
