export const gallery = [
  {
    href: "/img/hfw/HFW-AFB-1.jpg",
    title: "Helsinki Fashion Week",
    width: "800",
    height: "1067",
    src: "https://res.cloudinary.com/studio-pms/image/upload/v1659010862/HFW/HFW-AFB-1_suxaim.jpg",
  },
  {
    href: "/img/hfw/HFW-VIDEO-1.mov",
    title: "Helsinki Fashion Week",
    width: "800",
    height: "1067",
    type: "video",
    src: "https://res.cloudinary.com/studio-pms/video/upload/v1659010913/HFW/HFW-VIDEO-1_glgaci.mov",
  },
  {
    href: "/img/hfw/HFW-AFB-2.png",
    title: "Helsinki Fashion Week",
    width: "800",
    height: "1067",
    src: "https://res.cloudinary.com/studio-pms/image/upload/v1659010863/HFW/HFW-AFB-2_l5i6ms.png",
  },
  {
    href: "/img/hfw/HFW-AFB-3.jpg",
    title: "Helsinki Fashion Week",
    width: "800",
    height: "1067",
    src: "https://res.cloudinary.com/studio-pms/image/upload/v1659010815/HFW/HFW-AFB-3_fsoizf.jpg",
  },
  // {
    //   href: "/img/hfw/HFW-VIDEO-2.mov",
    //   title: "Helsinki Fashion Week",
    //   width: "800",
    //   height: "1067",
    //   type: "video",
    //   src: "/img/hfw/HFW-VIDEO-2.mov",
    // },
    {
      href: "/img/hfw/HFW-AFB-4.jpg",
      title: "Helsinki Fashion Week",
      width: "800",
      height: "1067",
      src: "https://res.cloudinary.com/studio-pms/image/upload/v1659010832/HFW/HFW-AFB-4_ohigkk.jpg",
    },
    {
      href: "/img/hfw/HFW-AFB-5.jpg",
      title: "Helsinki Fashion Week",
      width: "800",
      height: "1067",
      src: "https://res.cloudinary.com/studio-pms/image/upload/v1659010815/HFW/HFW-AFB-5_ebkxzu.jpg",
  },
];
