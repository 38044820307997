import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Autoplay,
  Pagination,
  Navigation,
  EffectCoverflow,
  EffectCube,
  EffectFade,
  EffectFlip,
} from "swiper";

import "swiper/css/bundle";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { device } from "../../constant/breakpoints";
import { burberry, centralMuseum, kassl } from "../../constant/project-gallery";

SwiperCore.use([
  Autoplay,
  Pagination,
  Navigation,
  EffectCoverflow,
  EffectCube,
  EffectFade,
  EffectFlip,
]);

const slides = [
  {
    href: "/img/aparrellel-existance/WhatsApp-Image-2019-11-01-at-15.21.10.jpeg",
    title: "",
    width: "1131",
    height: "1600",
    src: "/img/aparrellel-existance/WhatsApp-Image-2019-11-01-at-15.21.10.jpeg",
  },
  {
    href: "/img/bijenkorf-ss22-trends/Waarzegster-zoom-4-scaled.jpg",
    title: "",
    width: "1810",
    height: "2560",
    src: "/img/bijenkorf-ss22-trends/Waarzegster-zoom-4-scaled.jpg",
  },
  {
    href: "/img/in-de-maak/Zoom-scaled.jpg",
    title: "",
    width: "1811",
    height: "2560",
    src: "/img/in-de-maak/Zoom-scaled.jpg",
  },
  {
    href: "/img/ADIDAS-HYPEBAE/Kopie-van-Kopie-van-Still-2-final-render-scaled.jpg",
    title: "",
    width: "1802",
    height: "2560",
    src: "/img/ADIDAS-HYPEBAE/Kopie-van-Kopie-van-Still-2-final-render-scaled.jpg",
  },
  burberry[3],
  kassl[2],
  centralMuseum[3],
];

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  min-height: 1px;

  .elementor-widget-wrap {
    background-color: inherit;
    border-style: solid;
    border-color: #7f8678;
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s;
    margin: 0px 0px 0px 0px;
    border-width: 1px 0px 0px 0px;
    padding: 40px 0px 0px 0px;
    justify-content: center;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;

    .elementor-element {
      width: 100%;
      position: relative;

      .elementor-widget-container {
        margin: -45px 0px 0px 0px;
        padding: 0px 0px 0px 0px;

        .qodef-image-gallery {
          height: 833px;
          .swiper-wrapper {
            transform: translateZ(0);
            position: relative;
            width: 100%;
            height: 100%;
            z-index: 1;
            display: flex;
            transition-property: transform;
            transition-property: transform, -webkit-transform;
            box-sizing: content-box;
            img {
              transform: translateY(0) !important;
            }
          }
        }
      }
    }
  }

  @media ${device.mobileL} {
    .elementor-widget-wrap {
      .elementor-element {
        .elementor-widget-container {
          .qodef-image-gallery {
            height: 833px;
            .qodef-image-wrapper {
              position: relative;
              bottom: 0;
            }
          }
        }
      }
    }
  }
  @media ${device.tablet} {
    width: 50%;
    .elementor-widget-wrap {
      .elementor-element {
        .elementor-widget-container {
          margin: 0;
          .qodef-image-gallery {
            height: 100vh;
            img {
              transform: translateY(25px) !important;
            }
          }
        }
      }
    }
  }
  @media ${device.laptop} {
    .elementor-widget-wrap {
      .elementor-element {
        .elementor-widget-container {
          .qodef-image-gallery {
            height: 100vh;
          }
        }
      }
    }
  }
`;

const Img = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const HomeSlideShow = () => {
  return (
    <Wrapper
      className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-77e1143"
      data-id="77e1143"
      data-element_type="column"
      data-settings='{"background_background":"classic"}'
    >
      <div className="elementor-widget-wrap elementor-element-populated">
        <div
          className="elementor-element elementor-element-8ca41dc elementor-widget elementor-widget-laurits_core_image_gallery"
          data-id="8ca41dc"
          data-element_type="widget"
          data-widget_type="laurits_core_image_gallery.default"
        >
          <div className="elementor-widget-container">
            <Swiper
              spaceBetween={30}
              slidesPerView={1}
              loop
              autoplay
              navigation
              effect="fade"
              fadeEffect={{ crossFade: true }}
              className="qodef-shortcode qodef-m qodef-image-gallery qodef-full-height qodef-grid "
              pagination={{
                el: ".swiper-pagination",
                clickable: true,
              }}
            >
              {slides.map((slide, index) => (
                <SwiperSlide
                  key={index}
                  className="qodef-e qodef-image-wrapper"
                  style={{
                    width: "773px",
                  }}
                >
                  <Img
                    // width="1131"
                    // height="1600"
                    src={slide.src}
                    className="attachment-full size-full"
                    alt=""
                  />
                </SwiperSlide>
              ))}
              <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets"></div>
            </Swiper>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default HomeSlideShow;
