import React, { useState } from "react";
import styled from "styled-components";
import ModalVideo from "react-modal-video";
import getVideoID from "get-video-id";

import { device } from "../../constant/breakpoints";

const Wrapper = styled.div`
  width: 100%;
  @media ${device.tablet} {
    width: 50%;
    .qodef-portfolio-info-holder {
      width: 50%;
      position: static !important;
    }
  }
`;

const ProjectInfo = ({ title, videoUrl, metaData, children }) => {
  const [isOpen, setOpen] = useState(false);

  const video = getVideoID(videoUrl ?? "");

  return (
    // <Wrapper className="qodef-e-content-inner">
    <>
      {videoUrl && (
        <ModalVideo
          channel={video.service ?? "custom"}
          url={!video.service ? videoUrl : null}
          autoplay
          isOpen={isOpen}
          videoId={video.id}
          onClose={() => setOpen(false)}
        />
      )}
      <Wrapper className="qodef-portfolio-info-holder" style={{}}>
        <h1
          itemProp="name"
          className="qodef-e-title entry-title qodef-portfolio-title"
        >
          {title}
        </h1>
        <div className="qodef-e qodef-portfolio-content">
          <div
            data-elementor-type="wp-post"
            data-elementor-id="1345"
            className="elementor elementor-1345"
          >
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-7676dc45 elementor-section-boxed elementor-section-height-default elementor-section-height-default qodef-backgound-letter-holder-no qodef-elementor-content-no"
              data-id="7676dc45"
              data-element_type="section"
              style={{ overflow: "visible" }}
            >
              <div className="elementor-container elementor-column-gap-default">
                <div
                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5a057e94"
                  data-id="5a057e94"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-67709fab elementor-widget elementor-widget-text-editor"
                      data-id="67709fab"
                      data-element_type="widget"
                      data-widget_type="text-editor.default"
                    >
                      <div className="elementor-widget-container">
                        {children}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {videoUrl && (
              <section
                className="elementor-section elementor-top-section elementor-element elementor-element-fd153d2 elementor-section-boxed elementor-section-height-default elementor-section-height-default qodef-backgound-letter-holder-no qodef-elementor-content-no"
                data-id="fd153d2"
                data-element_type="section"
                style={{ overflow: "visible" }}
              >
                <div className="elementor-container elementor-column-gap-default">
                  <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-1676c6e"
                    data-id="1676c6e"
                    data-element_type="column"
                  >
                    <div className="elementor-widget-wrap elementor-element-populated">
                      <div
                        className="elementor-element elementor-element-7ec1072 wp-video-popup elementor-widget elementor-widget-button"
                        data-id="7ec1072"
                        data-element_type="widget"
                        data-widget_type="button.default"
                      >
                        <div className="elementor-widget-container">
                          <div className="elementor-button-wrapper">
                            <button
                              onClick={() => setOpen(true)}
                              style={{
                                background: "transparent",
                                border: "none",
                                padding: 0,
                              }}
                              className="qodef-underline"
                            >
                              <span className="elementor-button-content-wrapper">
                                <span className="elementor-button-text">
                                  WATCH VIDEO
                                </span>
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </div>
        </div>
        <div className="qodef-portfolio-info">
          {metaData?.map((meta) => (
            <div key={meta.key} className={`qodef-e qodef-info--${meta.key}`}>
              <h4 className="qodef-e-title">{meta.key} </h4>
              <span className={`qodef-e-${meta.key}`}>{meta.value}</span>
            </div>
          ))}
        </div>
      </Wrapper>
    </>
    // </Wrapper>
  );
};

export default ProjectInfo;
