import React from "react";
import styledComponents from "styled-components";

const Toggle = styledComponents.a`
`;

const MenuToggle = ({ onClick }) => {
  return (
    <Toggle
      onClick={onClick}
      className="qodef-opener-icon qodef-m qodef-source--svg-path mobile-header-opener"
    >
      <span className="qodef-m-icon qodef--open">
        <svg
          className="qodef-custom-svg-open"
          xmlns="http://www.w3.org/2000/svg"
          width={41.19}
          height={11}
          xmlSpace="preserve"
        >
          <line
            fill="none"
            strokeWidth="0.9977"
            x1="0.095"
            y1="0.5"
            x2="41.095"
            y2="0.5"
          ></line>
          <line
            fill="none"
            strokeWidth="0.9977"
            x1="0.095"
            y1="10.5"
            x2="41.095"
            y2="10.5"
          ></line>
        </svg>
      </span>
      <span className="qodef-m-icon qodef--close">
        <svg
          className="qodef-custom-svg-close"
          xmlns="http://www.w3.org/2000/svg"
          width={41.3}
          height={13}
          style={{
            enableBackground: "new 0 0 41.3 13",
          }}
          xmlSpace="preserve"
        >
          <g>
            <line className="st0" x1="41.1" y1="12.5" x2="0.1" y2="0.5"></line>
            <line className="st0" x1="0.1" y1="12.5" x2="41.1" y2="0.5"></line>
          </g>
        </svg>
      </span>
    </Toggle>
  );
};

export default MenuToggle;
