import React from "react";
import styled from "styled-components";
import { ListCircleIcon } from "../../../components/data-display/list-icon/ListCircleIcon";
import { device } from "../../../constant/breakpoints";

const Wrapper = styled.div`
  padding: 50px 0px 0px 0px;
  width: 100%;

  @media ${device.tablet} {
    width: 50%;
  }
  @media ${device.laptop} {
    width: 20%;
    padding: 0;
    margin: 0;
  }
`;

export const Clients = () => {
  return (
    <Wrapper
      className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-8d871a6"
      data-id="8d871a6"
      data-element_type="column"
    >
      <div className="elementor-widget-wrap elementor-element-populated">
        <div
          className="elementor-element elementor-element-b03b0e0 elementor-widget__width-initial elementor-widget elementor-widget-laurits_core_icon_with_text"
          data-id="b03b0e0"
          data-element_type="widget"
          data-widget_type="laurits_core_icon_with_text.default"
        >
          <div className="elementor-widget-container">
            <div className="qodef-shortcode qodef-m  qodef-icon-with-text qodef-layout--before-title qodef--custom-icon  ">
              <div className="qodef-m-content">
                <h4 className="qodef-m-title">
                  <span className="qodef-m-title-inner">
                    <ListCircleIcon />
                    <span className="qodef-m-title-text">Clients </span>
                  </span>
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div
          className="elementor-element elementor-element-d0aa069 elementor-widget__width-initial elementor-widget elementor-widget-text-editor"
          data-id="d0aa069"
          data-element_type="widget"
          data-widget_type="text-editor.default"
        >
          <div className="elementor-widget-container">
            <p style={{ color: "#000000" }}>Frame Magazine</p>{" "}
          </div>
        </div>
        <div
          className="elementor-element elementor-element-1a2ea40 elementor-widget__width-initial elementor-widget elementor-widget-text-editor"
          data-id="1a2ea40"
          data-element_type="widget"
          data-widget_type="text-editor.default"
        >
          <div className="elementor-widget-container">
            <p style={{ color: "#000000" }}>De Bijenkorf</p>{" "}
          </div>
        </div>
        <div
          className="elementor-element elementor-element-c8a2390 elementor-widget__width-initial elementor-widget elementor-widget-text-editor"
          data-id="c8a2390"
          data-element_type="widget"
          data-widget_type="text-editor.default"
        >
          <div className="elementor-widget-container">
            <p style={{ color: "#000000" }}>Centraal Museum</p>{" "}
          </div>
        </div>
        <div
          className="elementor-element elementor-element-bdd0aa0 elementor-widget__width-initial elementor-widget elementor-widget-text-editor"
          data-id="bdd0aa0"
          data-element_type="widget"
          data-widget_type="text-editor.default"
        >
          <div className="elementor-widget-container">
            <p style={{ color: "#000000" }}>Adidas</p>{" "}
          </div>
        </div>
        <div
          className="elementor-element elementor-element-7b0cefa elementor-widget__width-initial elementor-widget elementor-widget-text-editor"
          data-id="7b0cefa"
          data-element_type="widget"
          data-widget_type="text-editor.default"
        >
          <div className="elementor-widget-container">
            <p style={{ color: "#000000" }}>Burberry</p>{" "}
          </div>
        </div>
        <div
          className="elementor-element elementor-element-bd389e7 elementor-widget__width-initial elementor-widget elementor-widget-text-editor"
          data-id="bd389e7"
          data-element_type="widget"
          data-widget_type="text-editor.default"
        >
          <div className="elementor-widget-container">
            <p style={{ color: "#000000" }}>Hypebae</p>{" "}
          </div>
        </div>
        <div
          className="elementor-element elementor-element-f7fcb12 elementor-widget__width-initial elementor-widget elementor-widget-text-editor"
          data-id="f7fcb12"
          data-element_type="widget"
          data-widget_type="text-editor.default"
        >
          <div className="elementor-widget-container">
            <p style={{ color: "#000000" }}>Kassl Editions</p>{" "}
          </div>
        </div>
        <div
          className="elementor-element elementor-element-079cbdd elementor-widget__width-initial elementor-widget elementor-widget-text-editor"
          data-id="079cbdd"
          data-element_type="widget"
          data-widget_type="text-editor.default"
        >
          <div className="elementor-widget-container">
            <p style={{ color: "#000000" }}>Selfridges</p>{" "}
          </div>
        </div>
        <div
          className="elementor-element elementor-element-acc43fd elementor-widget__width-initial elementor-widget elementor-widget-text-editor"
          data-id="acc43fd"
          data-element_type="widget"
          data-widget_type="text-editor.default"
        >
          <div className="elementor-widget-container">
            <p style={{ color: "#000000" }}>Helsinki Fashion Week</p>{" "}
          </div>
        </div>
        <div
          className="elementor-element elementor-element-7d30edf elementor-widget__width-initial elementor-widget elementor-widget-text-editor"
          data-id="7d30edf"
          data-element_type="widget"
          data-widget_type="text-editor.default"
        >
          <div className="elementor-widget-container">
            <p style={{ color: "#000000" }}>Vlisco</p>{" "}
          </div>
        </div>
        <div
          className="elementor-element elementor-element-20b81bc elementor-widget__width-initial elementor-widget elementor-widget-text-editor"
          data-id="20b81bc"
          data-element_type="widget"
          data-widget_type="text-editor.default"
        >
          <div className="elementor-widget-container">
            <p style={{ color: "#000000" }}>Kadans</p>{" "}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
