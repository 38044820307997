export const gallery = [
  {
    href: "https://res.cloudinary.com/studio-pms/video/upload/v1659346865/kassl/AFW_VIDEO_1_ucaxsq.mov",
    title: "Kassl Edition",
    width: "800",
    height: "1067",
    type: "video",
    src: "https://res.cloudinary.com/studio-pms/video/upload/v1659346865/kassl/AFW_VIDEO_1_ucaxsq.mov",
  },
  {
    href: "https://res.cloudinary.com/studio-pms/image/upload/v1659085347/kassl/KASSL-EDITION_ovr9wy.png",
    title: "Kassl Edition",
    width: "800",
    height: "1067",
    src: "https://res.cloudinary.com/studio-pms/image/upload/v1659085347/kassl/KASSL-EDITION_ovr9wy.png",
  },
  {
    href: "https://res.cloudinary.com/studio-pms/image/upload/v1659346422/kassl/VoorlindenPMS_th5dbx.png",
    title: "Kassl Edition",
    width: "800",
    height: "1067",
    src: "https://res.cloudinary.com/studio-pms/image/upload/v1659346422/kassl/VoorlindenPMS_th5dbx.png",
  },
];
