import React from "react";
import styled from "styled-components";
import Form from "../../components/data-display/Form/Form";

const ContactTitleWrapper = styled.section`
  border-style: solid;
  border-width: 1px 0px 0px 0px;
  border-color: #000000;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  position: relative;

  .elementor-container {
    min-height: 200px;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    align-items: center;

    .elementor-column {
      width: 100%;
    }
  }
`;

const AboutContactForm = () => {
  return (
    <>
      <ContactTitleWrapper
        className="elementor-section elementor-top-section elementor-element elementor-element-0676094 elementor-section-full_width elementor-section-height-min-height elementor-section-height-default elementor-section-items-middle qodef-backgound-letter-holder-no qodef-elementor-content-no"
        data-id="0676094"
        data-element_type="section"
        style={{ overflow: "visible" }}
      >
        <div className="elementor-container elementor-column-gap-default">
          <div
            className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b5775b8"
            data-id="b5775b8"
            data-element_type="column"
          >
            <div className="elementor-widget-wrap elementor-element-populated">
              <div
                className="elementor-element elementor-element-665db70 elementor-widget elementor-widget-laurits_core_section_title"
                data-id="665db70"
                data-element_type="widget"
                data-widget_type="laurits_core_section_title.default"
              >
                <div className="elementor-widget-container">
                  <div className="qodef-shortcode qodef-m  qodef-section-title qodef-alignment--center qodef-title-break--disabled qodef-appear-animation--no ">
                    <span className="qodef-m-tagline">Say hi</span>
                    <h3 className="qodef-m-title" style={{ color: "#93421D" }}>
                      Let’s work together!{" "}
                    </h3>
                    <div className="qodef-m-button"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContactTitleWrapper>
      <Form />
    </>
  );
};

export default AboutContactForm;
