import getVideoId from "get-video-id";
import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import qs from "qs";

import LazyImage from "../../components/data-display/lazy-image/LazyImage";

const ProjectMedia = ({ mediaItems }) => {
  const [isOpen, setOpen] = useState(false);

  const generateMedia = () => {
    return mediaItems.map((media) => {
      if (media.type === "vimeo") {
        const video = getVideoId(media.src ?? "");
        const urlParts = media.src.split("?");
        let url = "";
        let config = {
          autoplay: 1,
          loop: 1,
          muted: 1,
          background: 1,
          controls: 0,
          sidedock: 0,
          title: 0,
          byline: 1,
          portrait: 0,
          transparent: 1,
        };

        if (urlParts.length > 1) {
          let params = qs.parse(media.src);

          config = {
            ...params,
          };

          url = qs.stringify(config, { encode: false });
        } else {
          url = `${media.src}?${qs.stringify(config, { encode: false })}`;
        }

        return (
          <React.Fragment key={media.src}>
            <span
              key={media.src}
              itemProp="image"
              className="qodef-popup-item qodef-grid-item"
              onClick={() => setOpen(true)}
              data-type="image"
              title={media.title}
            >
              <div className="attachment-full size-full">
                <iframe
                  title={media.title}
                  className="attachment-full size-full"
                  width="100%"
                  height={media.src}
                  src={url}
                  allow="autoplay"
                  frameBorder="0"
                  muted
                />
              </div>
            </span>
            <ModalVideo
              channel={video.service ?? "custom"}
              url={!video.service ? media.src : null}
              autoplay
              isOpen={isOpen}
              videoId={video.id}
              onClose={() => setOpen(false)}
            />
          </React.Fragment>
        );
      } else if (media.type === "video") {
        const video = getVideoId(media.src ?? "");
        return (
          <React.Fragment key={media.src}>
            <span
              key={media.src}
              itemProp="image"
              className="qodef-popup-item qodef-grid-item"
              onClick={() => setOpen(true)}
              data-type="image"
            >
              <video
                className="attachment-full size-full"
                width="100%"
                src={media.src}
                autoPlay
                loop
                muted
              />
            </span>
            <ModalVideo
              channel={video.service ?? "custom"}
              url={!video.service ? media.src : null}
              autoplay
              isOpen={isOpen}
              videoId={video.id}
              onClose={() => setOpen(false)}
            />
          </React.Fragment>
        );
      } else {
        return (
          <a
            key={media.src}
            itemProp="image"
            className="qodef-popup-item qodef-grid-item"
            href={media.src}
            data-type="image"
          >
            <LazyImage
              alt={media.title}
              height={media.height}
              src={media.src}
              width={media.width}
            />
          </a>
        );
      }
    });
  };
  return (
    <div className="qodef-e-content-inner">
      <div className="qodef-media">
        <div className="qodef-e qodef-magnific-popup qodef-popup-gallery">
          {generateMedia()}
        </div>
      </div>
    </div>
  );
};

export default ProjectMedia;
