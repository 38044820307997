import React from "react";
import styled from "styled-components";
import { ListCircleIcon } from "../../../components/data-display/list-icon/ListCircleIcon";
import { device } from "../../../constant/breakpoints";

const Wrapper = styled.div`
  padding: 50px 0px 0px 0px;
  width: 100%;

  @media ${device.tablet} {
    width: 50%;
  }
  @media ${device.laptop} {
    width: 20%;
    padding: 0;
    margin: 0;
  }
`;

export const BookPublications = () => {
  return (
    <Wrapper
      className="elementor-column elementor-col-25 elementor-top-column elementor-element elementor-element-3bd6966"
      data-id="3bd6966"
      data-element_type="column"
    >
      <div className="elementor-widget-wrap elementor-element-populated">
        <div
          className="elementor-element elementor-element-cacd539 elementor-widget__width-initial elementor-widget elementor-widget-laurits_core_icon_with_text"
          data-id="cacd539"
          data-element_type="widget"
          data-widget_type="laurits_core_icon_with_text.default"
        >
          <div className="elementor-widget-container">
            <div className="qodef-shortcode qodef-m  qodef-icon-with-text qodef-layout--before-title qodef--custom-icon  ">
              <div className="qodef-m-content">
                <h4 className="qodef-m-title">
                  <span className="qodef-m-title-inner">
                    <ListCircleIcon />
                    <span className="qodef-m-title-text">
                      Book Publications{" "}
                    </span>
                  </span>
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div
          className="elementor-element elementor-element-b467448 elementor-widget__width-initial elementor-widget elementor-widget-text-editor"
          data-id="b467448"
          data-element_type="widget"
          data-widget_type="text-editor.default"
        >
          <div className="elementor-widget-container">
            <p style={{ color: "#000000" }}>
              <span style={{ fontWeight: "400" }}>Current Magazine – 2022</span>
            </p>{" "}
          </div>
        </div>
        <div
          className="elementor-element elementor-element-07600ef elementor-widget__width-initial elementor-widget elementor-widget-text-editor"
          data-id="07600ef"
          data-element_type="widget"
          data-widget_type="text-editor.default"
        >
          <div className="elementor-widget-container">
            <p style={{ color: "#000000" }}>
              <span style={{ fontWeight: "400" }}>Page –&nbsp; 2020</span>
            </p>{" "}
          </div>
        </div>
        <div
          className="elementor-element elementor-element-6d5933b elementor-widget__width-initial elementor-widget elementor-widget-text-editor"
          data-id="6d5933b"
          data-element_type="widget"
          data-widget_type="text-editor.default"
        >
          <div className="elementor-widget-container">
            <p style={{ color: "#000000" }}>
              <span style={{ fontWeight: "400" }}>
                Fashion Tech – Marina Toeters 2019
              </span>
            </p>{" "}
          </div>
        </div>
        <div
          className="elementor-element elementor-element-0386fad elementor-widget__width-initial elementor-widget elementor-widget-text-editor"
          data-id="0386fad"
          data-element_type="widget"
          data-widget_type="text-editor.default"
        >
          <div className="elementor-widget-container">
            <p style={{ color: "#000000" }}>
              <span style={{ fontWeight: "400" }}>
                Frame magazine&nbsp; – N’128 May 2019
              </span>
            </p>{" "}
          </div>
        </div>
        <div
          className="elementor-element elementor-element-316bee7 elementor-widget__width-initial elementor-widget elementor-widget-text-editor"
          data-id="316bee7"
          data-element_type="widget"
          data-widget_type="text-editor.default"
        >
          <div className="elementor-widget-container">
            <p style={{ color: "#000000" }}>
              <span style={{ fontWeight: "400" }}>
                Design In conservative times – Onomatopee 179
              </span>
            </p>{" "}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
