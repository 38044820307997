import React, { useState } from "react";

import Logo from "../../data-display/logo/Logo";
import MenuToggle from "../../data-display/menu-toggle/MenuToggle";
import MobileMenu from "../../data-display/mobile-menu/MobileMenu";
import { StyledMobileHeader } from "./Header.styled";

const MobileHeader = ({ color }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <StyledMobileHeader
      id="qodef-page-mobile-header"
      role="banner"
      baseColor={color}
    >
      <div id="qodef-page-mobile-header-inner">
        <Logo />
        <MenuToggle onClick={() => setMenuOpen(!menuOpen)} />
        <MobileMenu isOpen={menuOpen} />
      </div>
    </StyledMobileHeader>
  );
};

export default MobileHeader;
