import React from "react";
import styled from "styled-components";

const MenuItem = styled.li`
  position: relative;
  margin: 6px 0;
  padding: 0;
  vertical-align: baseline;
  background: 0 0;
  border: 0;
  outline: 0;

  a {
    font-family: "Hatton", "Syne", sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-family: Syne, sans-serif;
    font-weight: 500;
    color: #000;
    font-size: 14px;
    line-height: 1.43em;

    &:before {
      content: "";
      width: 4px;
      height: 4px;
      left: 0;
      margin-right: 9px;
      position: relative;
      border: 1px solid currentColor;
      border-radius: 100%;
      display: inline-block;
      vertical-align: middle;
      background-color: ${({ selected }) =>
        selected ? "currentColor" : "transparent"};
      bottom: 1px;
    }
  }
`;

const MobileMenuItem = ({ isSelected, url, children }) => {
  return (
    <MenuItem
      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item"
      selected={isSelected}
    >
      <a href={url}>
        <span className="qodef-menu-item-text">{children}</span>
      </a>
    </MenuItem>
  );
};

export default MobileMenuItem;
