import React from "react";

import Header from "../../components/layout/header/Header";
import Footer from "../../components/layout/footer/Footer";
import projects from "../../constant/projects";
import ProjectThumb from "../../components/data-display/projectThumb/ProjectThumb";
import styled from "styled-components";
import { device } from "../../constant/breakpoints";
import Head from "../../components/layout/head/Head";

const pageColor = "#eef1ff";

const PageWrapper = styled.div`
  background-color: ${pageColor};
`;
const PageOuter = styled.div`
  margin-top: 0px !important;
`;
const PageInner = styled.div`
  padding: 50px 0px 130px 0px !important;
`;

const ProjectGrid = styled.div`
  width: 91%;
  margin: 0 auto;
  @media ${device.laptop} {
    width: 768px;
  }

  @media ${device.laptopL} {
    width: 960px;
  }
`;

const Projects = () => {
  document.body.classList.add("page-projects");
  document.body.style.backgroundColor = pageColor;

  return (
    <PageWrapper id="qodef-page-wrapper">
      <Head id="projects">Projects</Head>
      <Header color={pageColor} />

      <PageOuter id="qodef-page-outer" style={{ paddingTop: "0px" }}>
        <PageInner id="qodef-page-inner" className="qodef-content-full-width">
          <main
            id="qodef-page-content"
            className="qodef-grid qodef-layout--template "
            role="main"
          >
            <div className="qodef-grid-inner clear">
              <div className="qodef-grid-item qodef-page-content-section qodef-col--12">
                <div
                  data-elementor-type="wp-page"
                  data-elementor-id="2196"
                  className="elementor elementor-2196"
                >
                  <section
                    className="elementor-section elementor-top-section elementor-element elementor-element-1e10e5d elementor-section-full_width qodef-elementor-content-grid elementor-section-height-default elementor-section-height-default qodef-backgound-letter-holder-no"
                    data-id="1e10e5d"
                    data-element_type="section"
                    style={{ overflow: "visible" }}
                  >
                    <ProjectGrid className="elementor-container elementor-column-gap-no">
                      <div
                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9c33760"
                        data-id="9c33760"
                        data-element_type="column"
                      >
                        <div className="elementor-widget-wrap elementor-element-populated">
                          <div
                            className="elementor-element elementor-element-774719e elementor-widget elementor-widget-laurits_core_portfolio_list"
                            data-id="774719e"
                            data-element_type="widget"
                            data-settings='{"_animation":"none"}'
                            data-widget_type="laurits_core_portfolio_list.default"
                          >
                            <div className="elementor-widget-container">
                              <div
                                className="qodef-shortcode qodef-m  qodef-portfolio-list qodef-item-layout--info-on-hover qodef-item-border--no  qodef-side-border--on  qodef-appear-animation--no   qodef-grid qodef-layout--columns  qodef-gutter--medium qodef-col-num--2 qodef-item-layout--info-on-hover qodef--no-bottom-space qodef-pagination--off qodef-responsive--custom qodef-col-num--1440--2 qodef-col-num--1366--2 qodef-col-num--1024--2 qodef-col-num--768--2 qodef-col-num--680--1 qodef-col-num--480--1 qodef-hover-animation--fade-in"
                                data-options='{"plugin":"laurits_core","module":"post-types\/portfolio\/shortcodes","shortcode":"portfolio-list","post_type":"portfolio-item","next_page":"2","max_pages_num":1,"behavior":"columns","images_proportion":"full","columns":"2","columns_responsive":"custom","columns_1440":"2","columns_1366":"2","columns_1024":"2","columns_768":"2","columns_680":"1","columns_480":"1","space":"medium","display_tag_line":"no","display_letter":"no","display_item_border":"no","remove_side_borders":"no","equal_height":"no","display_show_more":"no","posts_per_page":"12","orderby":"date","order":"ASC","layout":"info-on-hover","hover_animation_info-follow":"follow","hover_animation_info-on-hover":"fade-in","title_tag":"h4","custom_padding":"no","items_appear_animation":"no","pagination_type":"no-pagination","object_class_name":"LauritsCore_Portfolio_List_Shortcode","taxonomy_filter":"portfolio-category","holder_data":{"data-appearing-delay":0},"space_value":20}'
                                data-appearing-delay="0"
                              >
                                <div className="qodef-grid-inner clear">
                                  
                                  {projects.map((project) => (
                                    <ProjectThumb key={project.title} {...project} />
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ProjectGrid>
                  </section>
                </div>
              </div>
            </div>
          </main>
        </PageInner>
      </PageOuter>
      <Footer color={pageColor} />
    </PageWrapper>
  );
};

export default Projects;
