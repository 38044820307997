import React from "react";
import { Helmet } from "react-helmet";

export default function Head({ id = "", children = "" }) {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{children && `${children} -`} Studio PMS</title>
      <link rel="apple-touch-icon" href="%PUBLIC_URL%/img/favicon.png" />
      <link rel="canonical" href={`https://studiopms.nl/${id.toLowerCase()}`} />
    </Helmet>
  );
}
