import React from "react";
import styled from "styled-components";
import { device } from "../../constant/breakpoints";

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  min-height: 1px;

  .elementor-widget-container {
    padding: 105px 0px 0px 0px;
  }

  .qodef-info-section.qodef-layout--default.qodef-layout--full-height {
    display: flex;
    flex-direction: column;
  }
  @media ${device.tablet} {
    width: 50%;
  }

  @media ${device.laptop} {
    .elementor-widget-container {
      padding: 0;
    }
  }
`;

const HomeIntro = () => {
  return (
    <Wrapper
      className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-85ccd92"
      data-id="85ccd92"
      data-element_type="column"
      data-settings='{"background_background":"classic"}'
    >
      <div className="elementor-widget-wrap elementor-element-populated">
        <div
          className="elementor-element elementor-element-084677f elementor-widget__width-initial elementor-widget-tablet__width-inherit elementor-widget elementor-widget-laurits_core_info_section"
          data-id="084677f"
          data-element_type="widget"
          data-widget_type="laurits_core_info_section.default"
        >
          <div className="elementor-widget-container">
            <div className="qodef-shortcode qodef-m qodef-info-section qodef-info-section-77 qodef-layout--default qodef-layout--full-height qodef-appear-animation--yes qodef-zoom-scroll-animation--no qodef--appeared">
              <div
                className="qodef-m-info qodef-info-middle-align"
                style={{ height: "calc(100% - 52px)" }}
              >
                <span className="qodef-m-tagline">Welcome to studio PMS </span>
                <h1 className="qodef-m-title" style={{ color: "#3F3551" }}>
                  We are a digital agency working on delivery truly unique
                  solutions to brands big & small.
                </h1>
                <div className="qodef-m-button">
                  <a
                    className="qodef-shortcode qodef-m  qodef-button qodef-layout--textual  qodef-html--link"
                    href="/projects"
                    target="_self"
                    style={{ color: "#3F3551" }}
                  >
                    {" "}
                    <span className="qodef-m-text">Explore more</span>
                  </a>{" "}
                </div>
              </div>
              <div className="qodef-m-info-bottom">
                <div className="qodef-m-item qodef-e">
                  <div
                    className="qodef-shortcode qodef-m qodef-social-links qodef-items-direction--vertical qodef-items-underline qodef-items-align--left qodef-appear-animation--yes qodef--appeared"
                    data-appearing-delay="540"
                  >
                    <div className="qodef-m-items">
                      <div className="qodef-m-item qodef-e">
                        <a
                          itemProp="url"
                          href="https://www.instagram.com/studio_pms/?hl=en"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span
                            className="qodef-e-text"
                            style={{ color: "#3F3551" }}
                          >
                            Instagram
                          </span>
                        </a>
                      </div>
                      <div className="qodef-m-item qodef-e">
                        <a
                          itemProp="url"
                          href="https://www.linkedin.com/company/studio-pms?challengeId=AQENE9PbyTtjlgAAAYBGIvSzimkVHTcaPSN9KIx9ItzIPcxKaxjk-tamJ--GYxpv8Gc9boWW3-RpawR4wgykJ38lIUJ7w-yeBQ&amp;submissionId=76e56bcd-328e-e716-05e9-8e0aa4b81a52"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span
                            className="qodef-e-text"
                            style={{ color: "#3F3551" }}
                          >
                            LinkedIn
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default HomeIntro;
