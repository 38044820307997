import React from "react";
import styled from "styled-components";

const Section = styled.section`
  border-style: solid;
  border-width: 1px 0px 0px 0px;
  border-color: #000000;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 130px 33px 0px 33px;
`;

const AboutIntro = () => {
  return (
    <Section
      className="elementor-section elementor-top-section elementor-element elementor-element-acabd1f elementor-section-full_width elementor-section-height-default elementor-section-height-default qodef-backgound-letter-holder-no qodef-elementor-content-no"
      data-id="acabd1f"
      data-element_type="section"
      style={{ overflow: "visible" }}
    >
      <div className="elementor-container elementor-column-gap-default">
        <div
          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-3986f4e"
          data-id="3986f4e"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-6c83fd2 elementor-widget__width-initial elementor-widget elementor-widget-laurits_core_section_title"
              data-id="6c83fd2"
              data-element_type="widget"
              data-widget_type="laurits_core_section_title.default"
            >
              <div className="elementor-widget-container">
                <div className="qodef-shortcode qodef-m  qodef-section-title qodef-alignment--left  qodef-appear-animation--no ">
                  <span className="qodef-m-tagline">Who are we</span>
                  <h2
                    className="qodef-m-title"
                    style={{
                      color: "#93421D",
                      marginTop: "15px",
                    }}
                  >
                    We are a digital agency working with young talents on
                    delivering truly unique aesthetic solutions to brands big
                    &amp; small.{" "}
                  </h2>
                  <div className="qodef-m-button"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default AboutIntro;
