import React, { useEffect } from "react";
import styled from "styled-components";

import Header from "../../components/layout/header/Header";
import Footer from "../../components/layout/footer/Footer";

import HomeSlideShow from "./HomeSlideShow";
import HomeIntro from "./HomeIntro";
import Head from "../../components/layout/head/Head";

const pageColor = "#dcd2db";

const HomeWrapper = styled.div`
  background-color: ${pageColor};
  
  .elementor-container {
    display: flex;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    flex-wrap: wrap;
  }
`;

const Home = () => {
  useEffect(() => {
    document.body.classList.add("page-home");
    
    return () => {
      document.body.classList.remove("page-home");
    }
  }, [])

  return (
    <HomeWrapper id="qodef-page-wrapper">
      <Head />
      <Header />

      <div id="qodef-page-outer" style={{ paddingTop: "0px" }}>
        <div id="qodef-page-inner" className="qodef-content-full-width">
          <main
            id="qodef-page-content"
            className="qodef-grid qodef-layout--template "
            role="main"
          >
            <div className="qodef-grid-inner clear">
              <div className="qodef-grid-item qodef-page-content-section qodef-col--12">
                <div className="elementor elementor-13">
                  <section
                    className="elementor-section elementor-top-section elementor-element elementor-element-ea2f62c elementor-section-full_width elementor-section-height-default elementor-section-height-default qodef-backgound-letter-holder-no qodef-elementor-content-no"
                    style={{ overflow: "visible" }}
                  >
                    <div className="elementor-container elementor-column-gap-default">
                      <HomeIntro />
                      <HomeSlideShow />
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </main>
        </div>
        {/* <!-- close #qodef-page-inner div from header.php --> */}
      </div>

      <Footer />
    </HomeWrapper>
  );
};

export default Home;
