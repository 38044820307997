import React, { useEffect } from "react";
import styled from "styled-components";

import Header from "../../components/layout/header/Header";
import Footer from "../../components/layout/footer/Footer";
import Head from "../../components/layout/head/Head";
import { Link } from "react-router-dom";

const pageColor = "#ffffff";

const PageWrapper = styled.div`
  background-color: ${pageColor};
`;
const PageOuter = styled.div`
  margin-top: 0px !important;
`;

const NotFound = () => {
  
  useEffect(() => {
    document.body.style.backgroundColor = pageColor;
    document.body.classList.add("page-not-found");
    
    return () => {
      document.body.classList.remove("page-not-found");
    }
  }, [])
  
  return (
    <PageWrapper id="qodef-page-wrapper">
      <Head id="not-found">Page Not Found</Head>
      <Header color={pageColor} />

      <PageOuter id="qodef-page-outer" style={{ paddingTop: "0px" }}>
      <div id="qodef-page-inner" className="qodef-content-full-width">
        <main id="qodef-page-content" role="main">
          <div id="qodef-404-page">
            <h1 className="qodef-404-title">Page Not Found</h1>
            <p className="qodef-404-text">The page you are looking for doesn't exist. It may have been moved or removed altogether. Please try searching for some other page, or return to the website's homepage to find what you're looking for.</p>
            <div className="qodef-404-button">
              <Link className="qodef-shortcode qodef-m  qodef-button qodef-layout--filled  qodef-html--link" to="/">
                <span className="qodef-m-text">Back to home</span>
              </Link>
            </div>
          </div>
        </main>
      </div>
        {/* <!-- close #qodef-page-inner div from header.php --> */}
      </PageOuter>

      <Footer color={pageColor} s/>
    </PageWrapper>
  );
};

export default NotFound;


