import * as React from "react";
import { Routes, Route } from "react-router-dom";
import About from "../pages/about/About";
import NotFound from "../pages/error/NotFound";

import Home from "../pages/home/Home";
import ProjectDetail from "../pages/project-detail/ProjectDetails";
import Projects from "../pages/projects/Projects";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about-us" element={<About />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/projects/:slug" element={<ProjectDetail />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Router;
