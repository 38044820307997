import React from "react";
import Logo from "../../data-display/logo/Logo";
import { StyledHeader } from "./Header.styled";
import MobileHeader from "./MobileHeader";

const pages = [
  { url: "/", label: "Home" },
  { url: "/about-us", label: "About Us" },
  { url: "/projects", label: "Projects" },
];

const isCurrentPage = (pathname) => {
  if (pathname.startsWith("/projects")) {
    return window.location.pathname.includes(pathname);
  }
  return window.location.pathname === pathname;
};

const Header = ({ color = "#dcd2db" }) => {
  return (
    <>
      <StyledHeader baseColor={color} id="qodef-page-header" role="banner">
        <div id="qodef-page-header-inner" className="">
          <div className="qodef-header-wrapper">
            <div className="qodef-header-logo">
              <Logo />
            </div>
            <nav
              className="qodef-header-navigation"
              role="navigation"
              aria-label="Top Menu"
            >
              <ul id="menu-main-menu-1" className="menu">
                {pages.map((page) => (
                  <li
                    key={page.url}
                    className={`menu-item ${
                      isCurrentPage(page.url)
                        ? "current-menu-item page_item current_page_item"
                        : ""
                    }`}
                  >
                    <a href={page.url}>
                      <span className="qodef-menu-item-text">{page.label}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </StyledHeader>
      <MobileHeader color={color} />
    </>
  );
};

export default Header;
