import React from "react";
import styled from "styled-components";
import {
  LazyLoadComponent,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import LazyImage from "../lazy-image/LazyImage";
import { device } from "../../../constant/breakpoints";

const StyledThumb = styled.article`
  position: relative;
  margin-bottom: 40px;
  cursor: pointer;
  overflow: hidden;

  .qodef-e-post-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .qodef-e-media-image {
    max-height: 613px;

    video {
      height: 100%;
      object-fit: cover;
    }
  }

  .qodef-e-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding: 37px 37px 30px;
    background-color: rgba(0, 0, 0, 0.12);
  }

  .qodef-e-text {
    opacity: 0;
    transform: translateY(8px);
    transition: opacity 0.66s cubic-bezier(0.4, 0.01, 0.14, 0.99),
      transform 0.66s cubic-bezier(0.4, 0.01, 0.14, 0.99);
    will-change: transform;
    * {
      color: white;
      &:hover {
        color: black;
      }
    }
  }

  &:hover {
    .qodef-e-text {
      opacity: 1;
      transform: translateY(0);
      transition-delay: 0.12s;
    }
  }

  @media ${device.tablet} {
    .qodef-e-media-image {
      height: 613px;
    }
  }
`;

const ProjectThumb = ({ title, slug, imgSrc, videoSrc }) => {
  return (
    <LazyLoadComponent>
      <StyledThumb className="qodef-e qodef-parallax-effect--no qodef-grid-item qodef-item--full post-1345 portfolio-item type-portfolio-item status-publish has-post-thumbnail hentry portfolio-category-art portfolio-category-collage portfolio-tag-creative">
        <div className="qodef-e-inner">
          <div
            className="qodef-e-media-image qodef--background"
            style={{
              backgroundImage: imgSrc ? `url(${imgSrc})` : null,
            }}
          >
            {videoSrc && (
              <video
                className="attachment-full size-full wp-post-image"
                width="100%"
                src={videoSrc}
                autoPlay
                loop
                muted
              />
            )}
            {imgSrc && (
              <LazyImage
                width="800"
                height="1067"
                src={imgSrc}
                className="attachment-full size-full wp-post-image"
                alt={title}
              />
            )}
          </div>
          <div className="qodef-e-content">
            <div className="qodef-e-text">
              <h4 itemProp="name" className="qodef-e-title entry-title">
                <a
                  itemProp="url"
                  className="qodef-e-title-link"
                  href={`/projects/${slug}`}
                  target="_self"
                >
                  {title}
                </a>
              </h4>
            </div>
          </div>
          {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
          <a
            itemProp="url"
            className="qodef-e-post-link"
            href={`/projects/${slug}`}
            target="_self"
          />
        </div>
      </StyledThumb>
    </LazyLoadComponent>
  );
};

export default trackWindowScroll(ProjectThumb);
