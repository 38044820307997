import React from "react";
import styled from "styled-components";

import Header from "../../components/layout/header/Header";
import Footer from "../../components/layout/footer/Footer";
import AboutHero from "./AboutHero";
import AboutIntro from "./AboutIntro";
import AboutContactForm from "./AboutContactForm";
import AboutTextGroupWrapper from "./AboutTextGroup";
import Head from "../../components/layout/head/Head";

const pageColor = "#f3eac3";

const PageWrapper = styled.div`
  background-color: ${pageColor};
`;
const PageOuter = styled.div`
  margin-top: 0px !important;
`;

const About = () => {
  document.body.style.backgroundColor = pageColor;
  document.body.classList.add("page-about-us");

  return (
    <PageWrapper id="qodef-page-wrapper">
      <Head id="about-us">About Us</Head>
      <Header color={pageColor} />

      <PageOuter id="qodef-page-outer" style={{ paddingTop: "0px" }}>
        <div id="qodef-page-inner" className="qodef-content-full-width">
          <main
            id="qodef-page-content"
            className="qodef-grid qodef-layout--template "
            role="main"
          >
            <div className="qodef-grid-inner clear">
              <div className="qodef-grid-item qodef-page-content-section qodef-col--12">
                <div
                  data-elementor-type="wp-page"
                  data-elementor-id="4826"
                  className="elementor elementor-4826"
                >
                  <AboutHero />
                  <AboutIntro />
                  <AboutTextGroupWrapper />
                  <AboutContactForm />
                </div>
              </div>
            </div>
          </main>
        </div>
        {/* <!-- close #qodef-page-inner div from header.php --> */}
      </PageOuter>

      <Footer color={pageColor} />
    </PageWrapper>
  );
};

export default About;
