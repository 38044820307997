import React from "react";
import styled from "styled-components";
import { Biography } from "./text-groups/Biography";
import { BookPublications } from "./text-groups/BookPublications";
import { Clients } from "./text-groups/Clients";
import { Publications } from "./text-groups/Publications";

const Section = styled.section`
  padding: 35px 33px 120px 33px;

  .elementor-container {
    display: flex;
    flex-wrap: wrap;
    margin-right: auto;
    margin-left: auto;
    position: relative;
  }
`;

const AboutTextGroupWrapper = () => {
  return (
    <Section
      className="elementor-section elementor-top-section elementor-element elementor-element-242f52b elementor-section-full_width elementor-section-height-default elementor-section-height-default qodef-backgound-letter-holder-no qodef-elementor-content-no"
      data-id="242f52b"
      data-element_type="section"
      style={{ overflow: "visible" }}
    >
      <div className="elementor-container elementor-column-gap-default">
        <Biography />
        <Publications />
        <Clients />
        <BookPublications />
      </div>
    </Section>
  );
};

export default AboutTextGroupWrapper;
