export const gallery = [
  {
    href: "https://res.cloudinary.com/studio-pms/video/upload/v1659362397/centraal-museum/FINAL_Polygon_ute0in.mp4",
    title: "Polygon",
    width: "800",
    height: "1067",
    type: "video",
    src: "https://res.cloudinary.com/studio-pms/video/upload/v1659362397/centraal-museum/FINAL_Polygon_ute0in.mp4",
  },
  {
    href: "https://res.cloudinary.com/studio-pms/image/upload/v1659362341/centraal-museum/T202204_009_UL_Pasvorm_polygon__foto_CMU-AvD_wj1wvg.jpg",
    title: "Polygon",
    width: "800",
    height: "1067",
    src: "https://res.cloudinary.com/studio-pms/image/upload/v1659362341/centraal-museum/T202204_009_UL_Pasvorm_polygon__foto_CMU-AvD_wj1wvg.jpg",
  },
  {
    href: "https://res.cloudinary.com/studio-pms/image/upload/v1659362342/centraal-museum/T202204_008_UL_Pasvorm_polygon__foto_CMU-AvD_ad9qzw.jpg",
    title: "Polygon",
    width: "800",
    height: "1067",
    src: "https://res.cloudinary.com/studio-pms/image/upload/v1659362342/centraal-museum/T202204_008_UL_Pasvorm_polygon__foto_CMU-AvD_ad9qzw.jpg",
  },
  {
    href: "https://res.cloudinary.com/studio-pms/image/upload/v1659362341/centraal-museum/T202204_040_UL_Pasvorm_polygon__foto_CMU-AvD_j04zke.jpg",
    title: "Polygon",
    width: "800",
    height: "1067",
    src: "https://res.cloudinary.com/studio-pms/image/upload/v1659362341/centraal-museum/T202204_040_UL_Pasvorm_polygon__foto_CMU-AvD_j04zke.jpg",
  },
  {
    href: "https://res.cloudinary.com/studio-pms/image/upload/v1659362341/centraal-museum/T202204_024_UL_Pasvorm_polygon__foto_CMU-AvD_gddd2l.jpg",
    title: "Polygon",
    width: "800",
    height: "1067",
    src: "https://res.cloudinary.com/studio-pms/image/upload/v1659362341/centraal-museum/T202204_024_UL_Pasvorm_polygon__foto_CMU-AvD_gddd2l.jpg",
  },
  {
    href: "https://res.cloudinary.com/studio-pms/image/upload/v1659362341/centraal-museum/T202204_025_UL_Pasvorm_polygon__foto_CMU-AvD_a8zp4j.jpg",
    title: "Polygon",
    width: "800",
    height: "1067",
    src: "https://res.cloudinary.com/studio-pms/image/upload/v1659362341/centraal-museum/T202204_025_UL_Pasvorm_polygon__foto_CMU-AvD_a8zp4j.jpg",
  },
  {
    href: "https://res.cloudinary.com/studio-pms/image/upload/v1659362340/centraal-museum/T202204_043_UL_Pasvorm_polygon__foto_CMU-AvD_hdhzhl.jpg",
    title: "Polygon",
    width: "800",
    height: "1067",
    src: "https://res.cloudinary.com/studio-pms/image/upload/v1659362340/centraal-museum/T202204_043_UL_Pasvorm_polygon__foto_CMU-AvD_hdhzhl.jpg",
  },
];
