import React from "react";
import styled from "styled-components";

const StyledLogo = styled.a`
  text-decoration: none;
`;

const Logo = () => {
  return (
    <StyledLogo
      itemProp="url"
      className="qodef-mobile-header-logo-link qodef-height--not-set qodef-source--textual"
      href="/"
      rel="home"
    >
      Studio PMS
    </StyledLogo>
  );
};

export default Logo;
